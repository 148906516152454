import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  Avatar,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import { sentenceCase } from "change-case";
import { Label } from "src/components";
// import { _update_customer_password } from "src/DAL/customer/customer";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Details({
  isOpenDrawer,
  onOpenDrawer,
  onCloseDrawer,
  selectedRow,
}) {
  const {
    address,
    created_at,
    email,
    first_name,
    id,
    is_active,
    last_name,
    phone,
    profile_pic,
    updated_at,
  } = selectedRow;

  console.log(selectedRow, "--selectedRow");
  return (
    <>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: { width: 350, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            <b>Guests's Details</b>
          </Typography>
          <IconButton onClick={onCloseDrawer}>
            <Icon icon="eva:close-fill" width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <div className="row p-3 w-100">
            <div className="col-12 text-center mb-2">
              {profile_pic ? (
                <img
                  alt={first_name + " " + last_name}
                  src={s3baseUrl + profile_pic}
                  className="w-50 pb-4"
                />
              ) : (
                <Typography variant="caption" noWrap>
                  Profile Image Not Available
                </Typography>
              )}
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>First Name:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                {first_name}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Last Name:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                {last_name}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Email:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                {email}
              </Typography>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Phone:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                {phone ? phone : "-"}
              </Typography>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Status:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Label
                variant="outlined"
                color={is_active === 0 ? "error" : "success"}
              >
                {sentenceCase((is_active === 0 && "banned") || "active")}
              </Label>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Created At:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2">
                {moment(created_at).format("LLL")}
              </Typography>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Updated At:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2">
                {moment(updated_at).format("LLL")}
              </Typography>
            </div>
            {/*  */}
          </div>
        </Scrollbar>
      </Drawer>
      {/* </Form> */}
      {/* </FormikProvider> */}
    </>
  );
}
