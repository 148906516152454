import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Iconify } from "src/components";
import { _get_customer_listing } from "src/DAL/customers";
import { _get_employee_listing } from "src/DAL/employee";
import { _add_manager_by_admin, _get_manager_listing } from "src/DAL/manager";
import { _add_project_by_admin } from "src/DAL/projects";
import { _upload_file } from "src/DAL/upload_file";
import {
  CURRENCIES_LIST,
  PRIORITY_STATUS_LIST,
  PROJECT_COMPLETION_LIST,
  PROJECT_FEE_STATUS_LIST,
  PROJECT_STATUS_LIST,
  PROJECT_TYPE_LIST,
} from "src/utils/constant";
import { get_local_preview } from "src/utils/utils_functions";
import { MAIN_ROUTE, TITLE_1 } from "../data";

function Add() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [clients, setClients] = useState([]);
  const [managers, setManagers] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [image, setImage] = useState("");
  const [inputs, setInputs] = useState({
    project_title: "",
    project_image: "",
    project_notes: "",
    project_type: "0",
    project_total_cost: 0,
    project_completion: 0,
    client_id: null,
    manager_id: null,
    team: [],
    currency: "NGN",
    budget: null,
    expected_fee: null,
    fee_paid: "0",
    project_status: "0",
    priority: "0",
    start_date: "",
    end_date: "",
    deadline_date: "",
    is_active: "1",
  });

  const handleChange = async (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let _image = inputs.project_image;
    if (image) {
      const formData = new FormData();
      formData.append("file", image);
      const res = await _upload_file(formData);
      if (res.code === 200) {
        _image = res.file_path;
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
        setIsLoading(false);
      }
    }
    const postData = {
      ...inputs,
      project_image: _image,
      team: JSON.stringify(inputs.team),
    };
    const res = await _add_project_by_admin(postData);
    if (res.code === 200) {
      navigate(MAIN_ROUTE);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (e) => {
    setImage(e.target.files[0]);
  };

  const handleRemoveImage = (e) => {
    setImage("");
  };

  const handleBack = async (e) => {
    navigate(MAIN_ROUTE);
  };

  const get_employee_profile = (id) => {
    const profile = employees.find((e) => e.id == id);
    return profile;
  };

  const get_customers_list = async () => {
    const resp = await _get_customer_listing();
    if (resp.code === 200) {
      setClients(resp.customers);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const get_managers_list = async () => {
    const resp = await _get_manager_listing();
    if (resp.code === 200) {
      setManagers(resp.managers);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const get_employees_list = async () => {
    const resp = await _get_employee_listing();
    if (resp.code === 200) {
      setEmployees(resp.employees);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_customers_list();
    get_managers_list();
    get_employees_list();
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-8">
            <form onSubmit={handleSubmit} className="row  mt-4">
              <div className="col-12">
                <IconButton edge="end" onClick={handleBack}>
                  <Iconify icon={"eva:arrow-back-outline"} />
                </IconButton>
              </div>
              <div className="col-12 text-center mb-4">
                <h4>Add New {TITLE_1}</h4>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Project Title"
                  name="project_title"
                  value={inputs.project_title}
                  onChange={handleChange}
                  required
                />
              </div>{" "}
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="date"
                  label="Start Date"
                  name="start_date"
                  value={inputs.start_date}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="date"
                  label="End Date"
                  name="end_date"
                  value={inputs.end_date}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="date"
                  label="Deadline Date"
                  name="deadline_date"
                  value={inputs.deadline_date}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Currency
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Currency"
                    name="currency"
                    value={inputs.currency}
                    onChange={handleChange}
                  >
                    {CURRENCIES_LIST.map((option) => (
                      <MenuItem value={option.value}>
                        {option.sign + " " + option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="number"
                  label="Client Budget"
                  name="budget"
                  value={inputs.budget}
                  onChange={handleChange}
                  // required
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="number"
                  label="Expected Fee"
                  name="expected_fee"
                  value={inputs.expected_fee}
                  onChange={handleChange}
                  // required
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="number"
                  label="Project Total Cost"
                  name="project_total_cost"
                  value={inputs.project_total_cost}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Client</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Client"
                    name="client_id"
                    value={inputs.client_id}
                    onChange={handleChange}
                  >
                    {clients.map((option) => (
                      <MenuItem value={`${option.id}`}>
                        {option.first_name + " " + option.last_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    ARO Manager
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="ARO Manager"
                    name="manager_id"
                    value={inputs.manager_id}
                    onChange={handleChange}
                  >
                    {managers.map((option) => (
                      <MenuItem value={`${option.id}`}>
                        {option.first_name + " " + option.last_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Team</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Team"
                    name="team"
                    multiple
                    value={inputs.team}
                    onChange={handleChange}
                    MenuProps={{ style: { maxHeight: 500 } }}
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}
                      >
                        {selected.map((option) => (
                          <Chip
                            size="small"
                            style={{ fontSize: 10, padding: 0 }}
                            key={option}
                            label={
                              get_employee_profile(option).first_name +
                              " " +
                              get_employee_profile(option).last_name
                            }
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {employees.map((option) => (
                      <MenuItem value={option.id}>
                        <Checkbox
                          checked={!!inputs.team.find((id) => id == option.id)}
                          // checked={checked}
                          // onChange={handleChange}
                        />
                        {option.first_name + " " + option.last_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Project Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Project Status"
                    name="project_status"
                    value={inputs.project_status}
                    onChange={handleChange}
                  >
                    {PROJECT_STATUS_LIST.map((option) => (
                      <MenuItem value={option.value}>
                        <Icon
                          fontSize={14}
                          className="me-2"
                          color={option.color}
                          icon="pajamas:status-active"
                        />
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Priority Level
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Priority Level"
                    name="priority"
                    value={inputs.priority}
                    onChange={handleChange}
                  >
                    {PRIORITY_STATUS_LIST.map((option) => (
                      <MenuItem value={option.value}>
                        <Icon
                          fontSize={16}
                          className="me-2"
                          color={option.color}
                          icon="material-symbols:flag"
                        />
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Fee Paid
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Fee Paid"
                    name="fee_paid"
                    value={inputs.fee_paid}
                    onChange={handleChange}
                  >
                    {PROJECT_FEE_STATUS_LIST.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Project Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Project Type"
                    name="project_type"
                    value={inputs.project_type}
                    onChange={handleChange}
                  >
                    {PROJECT_TYPE_LIST.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Project Completion
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Project Completion"
                    name="project_completion"
                    value={inputs.project_completion}
                    onChange={handleChange}
                  >
                    {PROJECT_COMPLETION_LIST.map((option) => (
                      <MenuItem value={option}>{option}%</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    name="is_active"
                    value={inputs.is_active}
                    onChange={handleChange}
                  >
                    <MenuItem value={"1"}>Active</MenuItem>
                    <MenuItem value={"0"}>Banned</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-12 mb-3">
                <TextField
                  fullWidth
                  label="Project Notes"
                  name="project_notes"
                  multiline
                  minRows={2}
                  value={inputs.project_notes}
                  onChange={handleChange}
                />
              </div>
              {image && (
                <div className="col-12 col-lg-12 mb-3">
                  <img width="50%" src={get_local_preview(image)} alt="" />
                  <Button
                    fullWidth
                    className="mt-3"
                    component="span"
                    variant="outlined"
                    color="error"
                    onClick={handleRemoveImage}
                  >
                    Remove Image
                  </Button>
                </div>
              )}
              {!image && (
                <div className="col-12 col-lg-12 mb-3">
                  <input
                    accept="image/png,image/jpeg,image/jpg"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={fileChangedHandler}
                  />
                  <label
                    htmlFor="contained-button-file"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Button
                      fullWidth
                      component="span"
                      variant="outlined"
                      color="primary"
                    >
                      Upload Project Image
                    </Button>
                  </label>
                </div>
              )}
              <div className="col-12 mb-3 text-center">
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                >
                  Add {TITLE_1}
                </LoadingButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add;
