import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { _add_employee, _update_employee } from "src/DAL/employee";
import { _upload_file } from "src/DAL/upload_file";
import {
  DEPARTMENT_LIST,
  EMPLOYMENT_TYPE,
  IDENTIFICATION_TYPE_LIST,
} from "src/utils/constant";
import { get_local_preview } from "src/utils/utils_functions";

function Edit() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    material_status: "",
    children: "",
    position: "",
    // password: "",
    date_of_birth: "",
    employment_type: "",
    state_of_origin: "",
    work_type: "",
    last_place_of_work: "",
    address: "",
    start_date: "",
    identification: "",
    department: "",
    next_of_kin: "",
    kin_phone_number: "",
    kin_address: "",
    notes: "",
    profile_pic: "",
    is_active: "1",
  });

  const handleChange = async (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleBack = async (e) => {
    navigate("/employees");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let profile_image = inputs.profile_pic;
    if (image) {
      const formData = new FormData();
      formData.append("file", image);
      const res = await _upload_file(formData);
      if (res.code === 200) {
        profile_image = res.file_path;
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
        setIsLoading(false);
      }
    }

    const postDate = {
      ...inputs,
      profile_pic: profile_image,
    };
    const res = await _update_employee(state.employee.id, postDate);
    if (res.code === 200) {
      handleBack();
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (e) => {
    setImage(e.target.files[0]);
  };

  const handleRemoveImage = (e) => {
    setImage("");
    setInputs({ ...inputs, profile_pic: "" });
  };

  useEffect(() => {
    if (state) {
      setInputs({
        first_name: state.employee.first_name,
        last_name: state.employee.last_name,
        email: state.employee.email,
        phone: state.employee.phone,
        material_status: String(state.employee.material_status),
        children: state.employee.children,
        position: state.employee.position,
        date_of_birth: state.employee.date_of_birth,
        employment_type: String(state.employee.employment_type),
        state_of_origin: state.employee.state_of_origin,
        work_type: state.employee.work_type,
        last_place_of_work: state.employee.last_place_of_work,
        address: state.employee.address,
        start_date: state.employee.start_date,
        identification: state.employee.identification,
        department: String(state.employee.department),
        notes: String(state.employee.notes),
        next_of_kin: state.employee.next_of_kin,
        kin_phone_number: state.employee.kin_phone_number,
        kin_address: state.employee.kin_address,
        profile_pic: state.employee.profile_pic,
        is_active: String(state.employee.is_active),
      });
    } else {
      handleBack();
    }
  }, []);

  return (
    <div>
      <div className="container">
        <form
          onSubmit={handleSubmit}
          className="row justify-content-center align-items-center"
        >
          <div className="col-12 col-lg-8">
            <div className="row  mt-4">
              <div className="col-12">
                <IconButton edge="end" onClick={handleBack}>
                  <Iconify icon={"eva:arrow-back-outline"} />
                </IconButton>
              </div>
              <div className="col-12 text-center mb-4">
                <h4>Add New Employee</h4>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="First Name"
                  name="first_name"
                  value={inputs.first_name}
                  onChange={handleChange}
                  required
                />
              </div>{" "}
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Last Name"
                  name="last_name"
                  value={inputs.last_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="number"
                  label="Phone"
                  name="phone"
                  value={inputs.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="email"
                  label="Email"
                  name="email"
                  value={inputs.email}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  name="password"
                  value={inputs.password}
                  required
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div> */}
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="date"
                  label="Start Date/Month"
                  name="start_date"
                  value={inputs.start_date}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="date"
                  label="Date of Birth"
                  name="date_of_birth"
                  value={inputs.date_of_birth}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="text"
                  label="Address"
                  name="address"
                  value={inputs.address}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Employment Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Employment Type"
                    name="employment_type"
                    value={inputs.employment_type}
                    onChange={handleChange}
                  >
                    {EMPLOYMENT_TYPE.map((option) => (
                      <MenuItem value={`${option.value}`}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Marital Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Marital Status"
                    name="material_status"
                    value={inputs.material_status}
                    onChange={handleChange}
                  >
                    <MenuItem value={"1"}>Single</MenuItem>
                    <MenuItem value={"2"}>Engage</MenuItem>
                    <MenuItem value={"3"}>Married</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="number"
                  label="No. of Children"
                  name="children"
                  value={inputs.children}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="text"
                  label="Position"
                  name="position"
                  value={inputs.position}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Identification
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Identification"
                    name="identification"
                    value={inputs.identification}
                    onChange={handleChange}
                  >
                    {IDENTIFICATION_TYPE_LIST.map((d) => (
                      <MenuItem value={`${d.value}`}>{d.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="text"
                  label="State of Origin"
                  name="state_of_origin"
                  value={inputs.state_of_origin}
                  onChange={handleChange}
                />
              </div>
              {/* <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="text"
                  label="Work Type"
                  name="work_type"
                  value={inputs.work_type}
                  onChange={handleChange}
                />
              </div> */}
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="text"
                  label="Last Place of Work"
                  name="last_place_of_work"
                  value={inputs.last_place_of_work}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Department
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Department"
                    name="department"
                    value={inputs.department}
                    onChange={handleChange}
                  >
                    {DEPARTMENT_LIST.map((d) => (
                      <MenuItem value={`${d.value}`}>{d.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="text"
                  label="Next of Kin"
                  name="next_of_kin"
                  value={inputs.next_of_kin}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="number"
                  label="Next of Kin Phone Number"
                  name="kin_phone_number"
                  value={inputs.kin_phone_number}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  type="text"
                  label="Next of Kin Address"
                  name="kin_address"
                  value={inputs.kin_address}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    name="is_active"
                    value={inputs.is_active}
                    onChange={handleChange}
                  >
                    <MenuItem value={"1"}>Active</MenuItem>
                    <MenuItem value={"0"}>Banned</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-12 mb-3">
                <TextField
                  fullWidth
                  label="Notes"
                  name="notes"
                  multiline
                  minRows={3}
                  value={inputs.notes}
                  onChange={handleChange}
                />
              </div>
              {image && (
                <div className="col-12 col-lg-12 mb-3">
                  <img width="50%" src={get_local_preview(image)} alt="" />
                  <Button
                    fullWidth
                    className="mt-3"
                    component="span"
                    variant="outlined"
                    color="error"
                    onClick={handleRemoveImage}
                  >
                    Remove Image
                  </Button>
                </div>
              )}
              {!image && inputs.profile_pic && (
                <div className="col-12 col-lg-12 mb-3">
                  <img
                    width="50%"
                    src={s3baseUrl + inputs.profile_pic}
                    alt=""
                  />
                  <Button
                    fullWidth
                    className="mt-3"
                    component="span"
                    variant="outlined"
                    color="error"
                    onClick={handleRemoveImage}
                  >
                    Remove Image
                  </Button>
                </div>
              )}
              {!image && !inputs.profile_pic && (
                <div className="col-12 col-lg-12 mb-3">
                  <input
                    accept="image/png,image/jpeg,image/jpg"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={fileChangedHandler}
                  />
                  <label
                    htmlFor="contained-button-file"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Button
                      fullWidth
                      component="span"
                      variant="outlined"
                      color="primary"
                    >
                      Upload Profile Image
                    </Button>
                  </label>
                </div>
              )}
              <div className="col-12 mb-3 text-center">
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                >
                  Edit employee
                </LoadingButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Edit;
