import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Iconify } from "src/components";
import { _add_customer_by_admin } from "src/DAL/customers";
import { _upload_file } from "src/DAL/upload_file";
import { get_local_preview } from "src/utils/utils_functions";

function Add() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    profile_pic: "",
    address: "",
    is_active: "1",
    customer_type: "1",
  });

  const handleChange = async (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let profile_image = inputs.profile_pic;
    if (image) {
      const formData = new FormData();
      formData.append("file", image);
      const res = await _upload_file(formData);
      if (res.code === 200) {
        profile_image = res.file_path;
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
        setIsLoading(false);
      }
    }
    const postDate = { ...inputs, profile_pic: profile_image };
    const res = await _add_customer_by_admin(postDate);
    if (res.code === 200) {
      navigate("/clients");
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (e) => {
    setImage(e.target.files[0]);
  };

  const handleRemoveImage = (e) => {
    setImage("");
  };

  const handleBack = async (e) => {
    navigate("/clients");
  };

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-6">
            <form onSubmit={handleSubmit} className="row  mt-4">
              <div className="col-12">
                <IconButton edge="end" onClick={handleBack}>
                  <Iconify icon={"eva:arrow-back-outline"} />
                </IconButton>
              </div>
              <div className="col-12 text-center mb-4">
                <h4>Add New Client</h4>
              </div>
              <div className="col-12 col-lg-12 mb-3">
                <TextField
                  fullWidth
                  label="First Name"
                  name="first_name"
                  value={inputs.first_name}
                  onChange={handleChange}
                  required
                />
              </div>{" "}
              <div className="col-12 col-lg-12 mb-3">
                <TextField
                  fullWidth
                  label="Last Name"
                  name="last_name"
                  value={inputs.last_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12 col-lg-12 mb-3">
                <TextField
                  fullWidth
                  type="number"
                  label="Phone"
                  name="phone"
                  value={inputs.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12 col-lg-12 mb-3">
                <TextField
                  fullWidth
                  type="email"
                  label="Email"
                  name="email"
                  value={inputs.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12 col-lg-12 mb-3">
                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  name="password"
                  value={inputs.password}
                  required
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-12 col-lg-12 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Status *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status *"
                    required
                    name="is_active"
                    value={inputs.is_active}
                    onChange={handleChange}
                  >
                    <MenuItem value={"1"}>Active</MenuItem>
                    <MenuItem value={"0"}>Banned</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {image && (
                <div className="col-12 col-lg-12 mb-3">
                  <img width="50%" src={get_local_preview(image)} alt="" />
                  <Button
                    fullWidth
                    className="mt-3"
                    component="span"
                    variant="outlined"
                    color="error"
                    onClick={handleRemoveImage}
                  >
                    Remove Image
                  </Button>
                </div>
              )}
              {!image && (
                <div className="col-12 col-lg-12 mb-3">
                  <input
                    accept="image/png,image/jpeg,image/jpg"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={fileChangedHandler}
                  />
                  <label
                    htmlFor="contained-button-file"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Button
                      fullWidth
                      component="span"
                      variant="outlined"
                      color="primary"
                    >
                      Upload Profile Image
                    </Button>
                  </label>
                </div>
              )}
              <div className="col-12 mb-3 text-center">
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                >
                  Add Client
                </LoadingButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add;
