import { invokeApi } from "src/utils";

export const _get_all_invoices = async (data) => {
  const requestObj = {
    path: `/v1/invoice/get_all_invoices`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_invoice_status = async (data) => {
  const requestObj = {
    path: `/v1/invoice/update_invoice_status`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_invoice_receipt = async (data) => {
  const requestObj = {
    path: `/v1/invoice/update_invoice_receipt`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_invoice = async (data) => {
  const requestObj = {
    path: `/v1/invoice/add_invoice`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_invoice = async (id, data) => {
  const requestObj = {
    path: `/v1/invoice/update_invoice/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_invoice = async (id) => {
  const requestObj = {
    path: `/v1/invoice/delete_invoice/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
