// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography, Divider } from "@mui/material";
// components
import SummaryCard from "./components/SummaryCard";
import { CircularLoader, Page } from "src/components";
//hooks
import { useAppContext } from "src/hooks";
import { useEffect, useState } from "react";
import { _dashboard_data } from "src/DAL/dashboard";
import { useSnackbar } from "notistack";
import {
  CURRENCIES_LIST,
  PRIORITY_STATUS_LIST,
  PROJECT_STATUS_LIST,
  USER_TYPE,
} from "src/utils/constant";
import { _get_user_from_local_storage } from "src/DAL/local_storage";
import { _get_projects_listing } from "src/DAL/projects";

export default function Dashboard() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { _get_user_profile } = useAppContext();
  const profile = _get_user_from_local_storage();
  const user_type = profile?.user_type;

  const [data, setData] = useState({
    admin_profiles_count: 0,
    client_profiles_count: 0,
    confirmed_invoice_count: 0,
    employee_profiles_count: 0,
    guest_customer_profiles_count: 0,
    manager_profiles_count: 0,
    pending_confirmation_count: 0,
    pending_invoice_count: 0,
  });

  const [isLoading, setIsLoading] = useState(true);

  const [projects, setSetProjects] = useState([]);

  const [isLoadingProject, setIsLoadingProject] = useState(true);

  // ----------------------------------------------------------------------//
  // admin data array
  // ----------------------------------------------------------------------//

  const admin_data = [
    {
      color: "secondary",
      title: "Managers",
      count: data.manager_profiles_count,
      icon: "wpf:administrator",
      path: "/managers",
    },
    {
      color: "info",
      title: "Employees",
      count: data.employee_profiles_count,
      icon: "clarity:employee-group-line",
      path: "/employees",
    },
    {
      color: "secondary",
      title: "Clients",
      count: data.client_profiles_count,
      icon: "ph:users-four",
      path: "/clients",
    },
    {
      color: "info",
      title: "Guests",
      count: data.guest_customer_profiles_count,
      icon: "fluent:guest-16-regular",
      path: "/guests",
    },

    {
      color: "secondary",
      title: "Pending Invoices",
      count: data.pending_invoice_count,
      icon: "fa-solid:file-invoice-dollar",
      path: "/invoices",
    },
    {
      color: "info",
      title: "Pending Confirmation",
      count: data.pending_confirmation_count,
      icon: "fa-solid:file-invoice-dollar",
      path: "/invoices",
    },
    {
      color: "secondary",
      title: "Completed Invoices",
      count: data.confirmed_invoice_count,
      icon: "fa-solid:file-invoice-dollar",
      path: "/invoices",
    },
  ];

  // ----------------------------------------------------------------------//
  // customer data array
  // ----------------------------------------------------------------------//
  const customer_data = [
    {
      color: "secondary",
      title: "Pending Invoices",
      count: data.pending_invoice_count,
      icon: "fa-solid:file-invoice-dollar",
      path: "/invoices",
    },
    {
      color: "info",
      title: "Pending Confirmation",
      count: data.pending_confirmation_count,
      icon: "fa-solid:file-invoice-dollar",
      path: "/invoices",
    },
    {
      color: "secondary",
      title: "Completed Invoices",
      count: data.confirmed_invoice_count,
      icon: "fa-solid:file-invoice-dollar",
      path: "/invoices",
    },
  ];

  let data_array = [];

  if (user_type === USER_TYPE.admin) {
    data_array = admin_data;
  }

  if (user_type === USER_TYPE.manager) {
    data_array = admin_data;
  }

  if (user_type === USER_TYPE.employee) {
    data_array = admin_data;
  }

  if (user_type === USER_TYPE.customer) {
    data_array = customer_data;
  }

  const handleSetData = async () => {
    const res = await _dashboard_data();
    if (res.code === 200) {
      setData(res.dashboard_data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const get_projects_by_status = (all_projects) => {
    let projects_by_status = PROJECT_STATUS_LIST.map((project_status) => {
      const projects = all_projects.filter(
        (project) => project.project_status === project_status.value
      );
      return {
        project_status,
        projects,
      };
    });
    return projects_by_status;
  };

  const get_projects_by_priority = (all_projects) => {
    let projects_by_status = PRIORITY_STATUS_LIST.map((project_priority) => {
      const projects = all_projects.filter(
        (project) => project.priority === project_priority.value
      );
      return {
        project_priority: project_priority,
        projects,
      };
    });
    return projects_by_status;
  };

  const get_projects_by_currency = (all_projects) => {
    let projects_by_currency = CURRENCIES_LIST.map((currency) => {
      const projects = all_projects.filter(
        (project) => project.currency === currency.value
      );

      let expected_fee = 0;
      let budget = 0;

      projects.forEach((project) => {
        if (project.expected_fee) {
          expected_fee += project.expected_fee;
        }

        if (project.budget) {
          budget += project.budget;
        }
      });

      return {
        currency: currency,
        projects,
        expected_fee,
        budget,
      };
    });
    return projects_by_currency;
  };

  const handleSetProjects = async () => {
    const resp = await _get_projects_listing();

    if (resp.code === 200) {
      setSetProjects(resp.projects);

      setIsLoadingProject(false);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  useEffect(() => {
    handleSetData();
    handleSetProjects();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          {data_array.map((item, i) => (
            <Grid key={i} item xs={12} sm={6} md={4}>
              <SummaryCard
                color={item.color}
                title={item.title}
                count={item.count}
                icon={item.icon}
                path={item.path}
              />
            </Grid>
          ))}
        </Grid>

        <Divider className="mt-4 mb-4" />

        {user_type === USER_TYPE.admin && (
          <>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Projects By Status
            </Typography>

            <Grid container spacing={3}>
              {get_projects_by_status(projects).map((item, i) => (
                <Grid key={i} item xs={12} sm={6} md={3}>
                  <SummaryCard
                    color="secondary"
                    title={item.project_status.label}
                    count={item.projects.length}
                    icon={"pajamas:status-active"}
                    // path={item.path}
                  />
                </Grid>
              ))}
            </Grid>

            <Divider className="mt-4 mb-4" />
            <Typography variant="h4" sx={{ mb: 5 }}>
              Projects By Priority
            </Typography>
            <Grid container spacing={3}>
              {get_projects_by_priority(projects).map((item, i) => (
                <Grid key={i} item xs={12} sm={6} md={3}>
                  <SummaryCard
                    color={"info"}
                    title={item.project_priority.label}
                    count={item.projects.length}
                    icon={"material-symbols:flag"}
                    // path={item.path}
                  />
                </Grid>
              ))}
            </Grid>
            <Divider className="mt-4 mb-4" />
            <Typography variant="h4" sx={{ mb: 5 }}>
              Projects By Currency
            </Typography>
            <Grid container spacing={3}>
              {get_projects_by_currency(projects).map((item, i) => (
                <Grid key={i} item xs={12} sm={6} md={3}>
                  <SummaryCard
                    color={"secondary"}
                    title={item.currency.label}
                    count={item.projects.length}
                    icon={item.currency.icon}
                    // path={item.path}
                  />
                </Grid>
              ))}
            </Grid>

            <Divider className="mt-4 mb-4" />
            <Typography variant="h4" sx={{ mb: 5 }}>
              Projects Expected Fee By Currency
            </Typography>
            <Grid container spacing={3}>
              {get_projects_by_currency(projects).map((item, i) => (
                <Grid key={i} item xs={12} sm={6} md={3}>
                  <SummaryCard
                    color={"info"}
                    title={item.currency.label}
                    count={item.expected_fee}
                    icon={item.currency.icon}
                    // path={item.path}
                  />
                </Grid>
              ))}
            </Grid>

            <Divider className="mt-4 mb-4" />
            <Typography variant="h4" sx={{ mb: 5 }}>
              Projects Budget By Currency
            </Typography>
            <Grid container spacing={3}>
              {get_projects_by_currency(projects).map((item, i) => (
                <Grid key={i} item xs={12} sm={6} md={3}>
                  <SummaryCard
                    color={"secondary"}
                    title={item.currency.label}
                    count={item.budget}
                    icon={item.currency.icon}
                    // path={item.path}
                  />
                </Grid>
              ))}
            </Grid>

            <Divider className="mt-4 mb-4" />
          </>
        )}
      </Container>
    </Page>
  );
}
