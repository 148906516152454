import { Navigate, useRoutes } from "react-router-dom";
import { _get_user_from_local_storage } from "./DAL/local_storage";
// layouts
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//pages
import {
  AddUser,
  Dashboard,
  ForgotPassword,
  Login,
  Page404,
  Profile,
  Register,
  Settings,
  Users,
  Managers,
  AddManager,
  EditManager,
  Employees,
  AddEmployee,
  EditEmployee,
  Invoices,
  AddInvoice,
  EditInvoice,
  Clients,
  AddClient,
  EditClient,
  Guests,
  AddGuest,
  EditGuest,
  Properties,
  AddProperty,
  EditProperty,
  Projects,
  AddProject,
  EditProject,
} from "./pages";
import { USER_TYPE } from "./utils/constant";

const get_role_base_routes = () => {
  const profile = _get_user_from_local_storage();
  const user_type = profile?.user_type;

  let routes = [];

  if (user_type === USER_TYPE.admin) {
    routes = [
      { path: "dashboard", element: <Dashboard /> },
      // manager
      { path: "managers", element: <Managers /> },
      { path: "managers/add-manager", element: <AddManager /> },
      { path: "managers/edit-manager/:id", element: <EditManager /> },

      // clients
      { path: "clients", element: <Clients /> },
      { path: "clients/add-client", element: <AddClient /> },
      { path: "clients/edit-client/:id", element: <EditClient /> },

      // guests
      { path: "guests", element: <Guests /> },
      { path: "guests/add-guest", element: <AddGuest /> },
      { path: "guests/edit-guest/:id", element: <EditGuest /> },

      // employee
      { path: "employees", element: <Employees /> },
      { path: "employees/add-employee", element: <AddEmployee /> },
      { path: "employees/edit-employee/:id", element: <EditEmployee /> },
      // billing
      { path: "invoices", element: <Invoices /> },
      { path: "invoices/add-invoice", element: <AddInvoice /> },
      { path: "invoices/edit-invoice/:id", element: <EditInvoice /> },
      //

      // Properties
      { path: "properties", element: <Properties /> },
      { path: "properties/add-property", element: <AddProperty /> },
      { path: "properties/edit-property/:id", element: <EditProperty /> },
      //

      // Projects
      { path: "projects", element: <Projects /> },
      { path: "projects/add-project", element: <AddProject /> },
      { path: "projects/edit-project/:id", element: <EditProject /> },
      //

      { path: "users/add-user", element: <AddUser /> },
      { path: "profile", element: <Profile /> },
      { path: "settings", element: <Settings /> },
    ];
  }

  if (user_type === USER_TYPE.manager) {
    routes = [
      { path: "dashboard", element: <Dashboard /> },

      { path: "employees", element: <Employees /> },
      { path: "employees/add-employee", element: <AddEmployee /> },
      { path: "employees/edit-employee/:id", element: <EditEmployee /> },

      { path: "users/add-user", element: <AddUser /> },
      { path: "profile", element: <Profile /> },
      { path: "settings", element: <Settings /> },

      // clients
      { path: "clients", element: <Clients /> },
      { path: "clients/add-client", element: <AddClient /> },
      { path: "clients/edit-client/:id", element: <EditClient /> },

      // guests
      { path: "guests", element: <Guests /> },
      { path: "guests/add-guest", element: <AddGuest /> },
      { path: "guests/edit-guest/:id", element: <EditGuest /> },

      // billing
      { path: "invoices", element: <Invoices /> },
      { path: "invoices/add-invoice", element: <AddInvoice /> },
      { path: "invoices/edit-invoice/:id", element: <EditInvoice /> },
      //
    ];
  }

  if (user_type === USER_TYPE.employee) {
    routes = [
      { path: "dashboard", element: <Dashboard /> },

      { path: "users/add-user", element: <AddUser /> },
      { path: "profile", element: <Profile /> },
      { path: "settings", element: <Settings /> },
    ];
  }

  if (user_type === USER_TYPE.customer) {
    routes = [
      { path: "dashboard", element: <Dashboard /> },
      // billing
      { path: "invoices", element: <Invoices /> },
    ];
  }

  return routes;
};

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: get_role_base_routes(),
    },

    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
