import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  Avatar,
  Card,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Scrollbar from "src/components/Scrollbar";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import { sentenceCase } from "change-case";
import { Label } from "src/components";
import { _get_customer_details } from "src/DAL/customers";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  get_ownership_type_label,
  get_unit_type_label,
  is_available,
} from "src/utils/utils_functions";
// import { _update_customer_password } from "src/DAL/customer/customer";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Details({
  isOpenDrawer,
  onOpenDrawer,
  onCloseDrawer,
  selectedRow,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(selectedRow);
  const [isLoading, setIsLoading] = useState(true);

  console.log(selectedRow, "--");
  const handleSetData = async (id) => {
    setIsLoading(true);
    const resp = await _get_customer_details(id);
    if (resp.code === 200) {
      setData(resp.customer);
      setIsLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const {
    address,
    created_at,
    email,
    first_name,
    id,
    is_active,
    last_name,
    phone,
    profile_pic,
    updated_at,
    properties,
  } = data;

  useEffect(() => {
    if (selectedRow.id) {
      handleSetData(selectedRow.id);
    }
  }, [isOpenDrawer]);
  return (
    <>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: { width: 350, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            <b>Clients's Details</b>
          </Typography>
          <IconButton onClick={onCloseDrawer}>
            <Icon icon="eva:close-fill" width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <div className="row p-3 w-100">
            <div className="col-12 text-center mb-2">
              {profile_pic ? (
                <img
                  alt={first_name + " " + last_name}
                  src={s3baseUrl + profile_pic}
                  className="w-50 pb-4"
                />
              ) : (
                <Typography variant="caption" noWrap>
                  Profile Image Not Available
                </Typography>
              )}
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>First Name:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                {first_name}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Last Name:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                {last_name}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Email:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                {email}
              </Typography>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Phone:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                {phone ? phone : "-"}
              </Typography>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Status:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Label
                variant="outlined"
                color={is_active === 0 ? "error" : "success"}
              >
                {sentenceCase((is_active === 0 && "banned") || "active")}
              </Label>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Created At:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2">
                {moment(created_at).format("LLL")}
              </Typography>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Updated At:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2">
                {moment(updated_at).format("LLL")}
              </Typography>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-12">
              <Typography variant="subtitle2" noWrap>
                <b>Properties:</b>
              </Typography>
            </div>
            <div className="col-12">
              {properties?.map((property, i) => (
                <div className="card mt-2">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{property.property_name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="row">
                        <div className="col-12 mb-2">
                          <img
                            className="w-100"
                            src={s3baseUrl + property.image}
                            alt=""
                          />

                          {property.image_2 && (
                            <img
                              className="w-100 mt-1"
                              src={s3baseUrl + property.image_2}
                              alt=""
                            />
                          )}
                        </div>
                        <div className="col-6">
                          <Typography variant="subtitle2" noWrap>
                            <b>Property Name:</b>
                          </Typography>
                        </div>
                        <div className="col-6">
                          <Typography variant="subtitle2">
                            {property.property_name}
                          </Typography>
                        </div>
                        <Divider className="mb-2 mt-2" />
                        <div className="col-6">
                          <Typography variant="subtitle2" noWrap>
                            <b>House No. :</b>
                          </Typography>
                        </div>
                        <div className="col-6">
                          <Typography variant="subtitle2">
                            {property.house_number}
                          </Typography>
                        </div>
                        <Divider className="mb-2 mt-2" />
                        <div className="col-6">
                          <Typography variant="subtitle2" noWrap>
                            <b>Bedrooms:</b>
                          </Typography>
                        </div>
                        <div className="col-6">
                          <Typography variant="subtitle2">
                            {is_available(property.number_of_bedrooms)}
                          </Typography>
                        </div>
                        <Divider className="mb-2 mt-2" />
                        <div className="col-6">
                          <Typography variant="subtitle2" noWrap>
                            <b>Bathrooms:</b>
                          </Typography>
                        </div>
                        <div className="col-6">
                          <Typography variant="subtitle2">
                            {is_available(property.number_of_bathrooms)}
                          </Typography>
                        </div>
                        <Divider className="mb-2 mt-2" />

                        <div className="col-6">
                          <Typography variant="subtitle2" noWrap>
                            <b>Unit Type:</b>
                          </Typography>
                        </div>
                        <div className="col-6">
                          <Typography variant="subtitle2">
                            {get_unit_type_label(property.unit_type)}
                          </Typography>
                        </div>
                        <Divider className="mb-2 mt-2" />

                        <div className="col-6">
                          <Typography variant="subtitle2" noWrap>
                            <b>Ownership Type:</b>
                          </Typography>
                        </div>
                        <div className="col-6">
                          <Typography variant="subtitle2">
                            {get_ownership_type_label(property.ownership_type)}
                          </Typography>
                        </div>
                        <Divider className="mb-2 mt-2" />

                        <div className="col-6">
                          <Typography variant="subtitle2" noWrap>
                            <b>State:</b>
                          </Typography>
                        </div>
                        <div className="col-6">
                          <Typography variant="subtitle2">
                            {is_available(property.state)}
                          </Typography>
                        </div>
                        <Divider className="mb-2 mt-2" />

                        <div className="col-6">
                          <Typography variant="subtitle2" noWrap>
                            <b>Country:</b>
                          </Typography>
                        </div>
                        <div className="col-6">
                          <Typography variant="subtitle2">
                            {is_available(property.country)}
                          </Typography>
                        </div>
                        <Divider className="mb-2 mt-2" />
                        {/* address */}
                        <div className="col-12">
                          <Typography variant="subtitle2" noWrap>
                            <b>Address:</b>
                          </Typography>
                        </div>
                        <div className="col-12">
                          <Typography variant="subtitle2">
                            {property.address}
                          </Typography>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
            </div>
            {/*  */}
          </div>
        </Scrollbar>
      </Drawer>
      {/* </Form> */}
      {/* </FormikProvider> */}
    </>
  );
}
