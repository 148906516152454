import {
  CURRENCIES_LIST,
  DEPARTMENT_LIST,
  EMPLOYMENT_TYPE,
  IDENTIFICATION_TYPE_LIST,
  LIST_OF_MONTHS,
  OWNERSHIP_TYPE_LIST,
  PRIORITY_STATUS_LIST,
  PROJECT_FEE_STATUS_LIST,
  PROJECT_STATUS_LIST,
  PROJECT_TYPE_LIST,
  UNIT_TYPE_LIST,
  USER_TYPE,
  WORK_TYPE_LIST,
} from "./constant";

export const get_local_preview = (file) => {
  if (file) {
    return URL.createObjectURL(file);
  } else {
    return "";
  }
};

export const get_month_name = (month) => {
  const _month = LIST_OF_MONTHS.find((m) => m.value === month);
  if (_month) {
    return _month.label;
  } else {
    return "Invalid month number";
  }
};

export const get_department_label = (value) => {
  const result = DEPARTMENT_LIST.find((m) => m.value == value);
  if (result) {
    return result.label;
  } else {
    return "Invalid department";
  }
};

export const get_unit_type_label = (value) => {
  const result = UNIT_TYPE_LIST.find((m) => m.value == value);
  if (result) {
    return result.label;
  } else {
    return "Invalid UNIT_TYPE";
  }
};

export const get_ownership_type_label = (value) => {
  const result = OWNERSHIP_TYPE_LIST.find((m) => m.value == value);
  if (result) {
    return result.label;
  } else {
    return "Invalid OWNERSHIP_TYPE";
  }
};

export const get_employment_type = (value) => {
  const result = EMPLOYMENT_TYPE.find((m) => m.value == value);
  if (result) {
    return result.label;
  } else {
    return "Invalid EMPLOYMENT_TYPE";
  }
};

export const get_work_type = (value) => {
  const result = WORK_TYPE_LIST.find((m) => m.value == value);
  if (result) {
    return result.label;
  } else {
    return "Invalid WORK_TYPE";
  }
};

export const get_identification = (value) => {
  const result = IDENTIFICATION_TYPE_LIST.find((m) => m.value == value);
  if (result) {
    return result.label;
  } else {
    return "Invalid IDENTIFICATION";
  }
};

export const get_currency_sign = (value) => {
  const result = CURRENCIES_LIST.find((m) => m.value == value);
  if (result) {
    return result.sign;
  } else {
    return "Invalid Currency";
  }
};

export const get_material_status = (type) => {
  if (type == 1) {
    return "Single";
  } else if (type == 2) {
    return "Married";
  } else if (type == 3) {
    return "Engaged";
  } else {
    return "Invalid material status";
  }
};

export const get_user_role = (type) => {
  if (type === USER_TYPE.admin) {
    return "Admin";
  } else if (type === USER_TYPE.manager) {
    return "Manager";
  } else if (type === USER_TYPE.employee) {
    return "Employee";
  } else if (type === USER_TYPE.customer) {
    return "Customer";
  } else {
    return "Invalid User Type";
  }
};

export const is_available = (value) => {
  return value ? value : "-";
};

export const get_project_status = (value) => {
  const result = PROJECT_STATUS_LIST.find((m) => m.value == value);
  if (result) {
    return result;
  } else {
    return {
      label: "Not Available",
      value: 0,
      color: "rgb(150, 146, 146)",
      variant: "default",
    };
  }
};

export const get_priority_status = (value) => {
  const result = PRIORITY_STATUS_LIST.find((m) => m.value == value);
  if (result) {
    return result;
  } else {
    return {
      label: "Not Available",
      value: 0,
      color: "rgb(150, 146, 146)",
      variant: "default",
    };
  }
};

export const get_project_fee_status = (value) => {
  const result = PROJECT_FEE_STATUS_LIST.find((m) => m.value == value);
  if (result) {
    return result;
  } else {
    return {
      label: "Not Available",
      value: 0,
      color: "rgb(150, 146, 146)",
      variant: "default",
    };
  }
};

export const get_project_type = (value) => {
  const result = PROJECT_TYPE_LIST.find((m) => m.value == value);
  if (result) {
    return result.label;
  } else {
    return "Not Available";
  }
};

export const is_small_screen = () => {
  if (window.innerWidth < 500) {
    return true;
  } else {
    return false;
  }
};
