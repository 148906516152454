export const _set_token_in_local_storage = (token) => {
  localStorage.setItem("token", token);
};

export const _get_token_from_local_storage = (token) => {
  return localStorage.getItem("token");
};

export const _set_user_in_local_storage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const _get_user_from_local_storage = () => {
  return JSON.parse(localStorage.getItem("user"));
};
