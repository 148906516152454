import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
import { _register } from "src/DAL/auth";
import { useSnackbar } from "notistack";
import {
  _set_token_in_local_storage,
  _set_user_in_local_storage,
} from "src/DAL/local_storage";

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    customer_type: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const handleChange = async (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputs.password !== inputs.confirm_password) {
      enqueueSnackbar("Please enter the same password.", { variant: "error" });
      return;
    }

    const postDate = {
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      email: inputs.email,
      password: inputs.password,
      customer_type: inputs.customer_type,
    };
    setIsLoading(true);
    const res = await _register(postDate);

    if (res.code === 200) {
      _set_token_in_local_storage(res.token);
      _set_user_in_local_storage({
        ...res.user,
        ...res.profile,
        user_id: res.user.id,
        profile_id: res.profile.id,
      });
      // navigate("/dashboard");
      location.reload();
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <div className="row">
          <div className="col-12 col-lg-6">
            <TextField
              fullWidth
              label="First Name"
              name="first_name"
              value={inputs.first_name}
              onChange={handleChange}
              required
            />
          </div>{" "}
          <div className="col-12 col-lg-6">
            <TextField
              fullWidth
              label="Last Name"
              name="last_name"
              value={inputs.last_name}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Customer Role</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Customer Role"
            required
            name="customer_type"
            value={inputs.customer_type}
            onChange={handleChange}
          >
            <MenuItem value={"1"}>ARO Management - Client</MenuItem>
            <MenuItem value={"2"}>ARO Apartment - Guest</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          type="email"
          label="Email"
          name="email"
          value={inputs.email}
          onChange={handleChange}
          required
        />

        <TextField
          fullWidth
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          label="Password"
          name="password"
          value={inputs.password}
          required
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          autoComplete="current-password"
          type={showPassword2 ? "text" : "password"}
          label="Confirm Password"
          name="confirm_password"
          value={inputs.confirm_password}
          onChange={handleChange}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword2((prev) => !prev)}
                >
                  <Iconify
                    icon={showPassword2 ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isLoading}
        >
          Register
        </LoadingButton>
      </Stack>
    </form>
  );
}
