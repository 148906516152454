// @mui
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "src/utils";
// components
import Iconify from "../../../components/Iconify";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

SummaryCard.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function SummaryCard({
  title,
  count,
  icon,
  path = "",
  color = "primary",
  sx,
  ...other
}) {
  const navigate = useNavigate();
  return (
    <Card
      onClick={() => navigate(path)}
      className="custom-link"
      sx={{
        boxShadow: 0,
        textAlign: "start",
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <div className="row justify-content-center align-items-center pt-4 pb-4 ps-4">
        <div className="col-7">
          <Typography variant="subtitle1" sx={{ opacity: 0.72 }}>
            {title}
          </Typography>
          <Typography variant="h3">{fShortenNumber(count)}</Typography>
        </div>
        <div className="col-5 d-flex justify-content-center align-items-center">
          <IconWrapperStyle
            sx={{
              color: (theme) => theme.palette[color].dark,
              margin: 0,
              backgroundImage: (theme) =>
                `linear-gradient(135deg, ${alpha(
                  theme.palette[color].dark,
                  0
                )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
            }}
          >
            <Iconify icon={icon} width={24} height={24} />
          </IconWrapperStyle>
        </div>
      </div>
    </Card>
  );
}
