import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  Avatar,
  AvatarGroup,
  LinearProgress,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import { sentenceCase } from "change-case";
import { Label } from "src/components";
import { TITLE_1 } from "../data";
import {
  get_currency_sign,
  get_priority_status,
  get_project_fee_status,
  get_project_status,
  get_project_type,
  is_available,
  is_small_screen,
} from "src/utils/utils_functions";
// import { _update_customer_password } from "src/DAL/customer/customer";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Details({
  isOpenDrawer,
  onOpenDrawer,
  onCloseDrawer,
  selectedRow,
  get_team,
}) {
  const data = selectedRow;

  console.log(selectedRow, "--selectedRow");

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: {
            width: is_small_screen() ? "100%" : 450,
            border: "none",
            overflow: "hidden",
          },
          className: "theme-color-card",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            <b>{TITLE_1}'s Details</b>
          </Typography>
          <IconButton onClick={onCloseDrawer}>
            <Icon icon="eva:close-fill" width={20} height={20} />
          </IconButton>
        </Stack>

        <Scrollbar>
          <div className="row p-3 w-100">
            {/*  */}
            <div className="col-12 text-center mb-2">
              {data.project_image ? (
                <img
                  src={s3baseUrl + data.project_image}
                  className="w-100 pb-4"
                />
              ) : (
                <Typography variant="caption" noWrap>
                  Project Image Not Available
                </Typography>
              )}
            </div>
            <Divider className="mb-2 mt-2" />

            <div className="col-12 mb-2">
              <Typography variant="subtitle2" noWrap>
                <b>Project Notes:</b>
              </Typography>
            </div>
            <div className="col-12">
              <Typography variant="subtitle2">
                {is_available(data.project_notes)}
              </Typography>
            </div>
            <Divider className="mb-2 mt-2" />
            {/*  */}
            <div className="col-12">
              <Typography variant="subtitle2" noWrap>
                <b>Project Completion:</b>
              </Typography>
            </div>
            <div className="col-12 mt-1 text-center">
              <Typography variant="subtitle2" noWrap>
                {data.project_completion + "%"}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={data.project_completion}
              />
            </div>
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Project Title:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                {is_available(data.project_title)}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Project Status:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                <Icon
                  fontSize={12}
                  className="me-2"
                  color={get_project_status(data.project_status)?.color}
                  icon="pajamas:status-active"
                />
                {get_project_status(data.project_status)?.label}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Project Priority:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                <Icon
                  fontSize={16}
                  className="me-2"
                  color={get_priority_status(data.priority)?.color}
                  icon="material-symbols:flag"
                />
                {get_priority_status(data.priority)?.label}
              </Typography>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Budget:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                {data.budget
                  ? is_available(
                      get_currency_sign(data.currency) + " " + data.budget
                    )
                  : "-"}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Expected Fee:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                {data.expected_fee
                  ? is_available(
                      get_currency_sign(data.currency) + " " + data.expected_fee
                    )
                  : "-"}
              </Typography>
            </div>

            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Project Total Cost:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                {data.project_total_cost
                  ? is_available(
                      get_currency_sign(data.currency) +
                        " " +
                        data.project_total_cost
                    )
                  : "-"}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Project Type:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                {get_project_type(data.project_type)}
              </Typography>
            </div>

            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Fee Paid:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Label color={get_project_fee_status(data.fee_paid)?.variant}>
                {sentenceCase(get_project_fee_status(data.fee_paid)?.label)}
              </Label>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Start Date:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                <Typography variant="subtitle2" noWrap>
                  {data.start_date ? moment(data.start_date).format("LL") : "-"}
                </Typography>
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>End Date:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                <Typography variant="subtitle2" noWrap>
                  {data.end_date ? moment(data.end_date).format("LL") : "-"}
                </Typography>
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Deadline Date:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2" noWrap>
                <Typography variant="subtitle2" noWrap>
                  {data.deadline_date
                    ? moment(data.deadline_date).format("LL")
                    : "-"}
                </Typography>
              </Typography>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Project Client:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Avatar
                  style={{ height: 30, width: 30 }}
                  alt={data.client_first_name + " " + data.client_last_name}
                  src={s3baseUrl + data.client_profile_pic}
                />
                <Typography variant="subtitle2" noWrap>
                  {data.client_first_name + " " + data.client_last_name}
                </Typography>
              </Stack>
            </div>

            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Project Manager:</b>
              </Typography>
            </div>

            <div className="col-8">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Avatar
                  style={{ height: 30, width: 30 }}
                  alt={data.manager_first_name + " " + data.manager_last_name}
                  src={s3baseUrl + data.manager_profile_pic}
                />
                <Typography variant="subtitle2" noWrap>
                  {data.manager_first_name + " " + data.manager_last_name}
                </Typography>
              </Stack>
            </div>

            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Team Members:</b>
              </Typography>
            </div>

            <div className="col-8">
              {get_team(data.team).map((team_member) => (
                <Stack
                  className="mt-2"
                  direction="row"
                  alignItems="center"
                  spacing={2}
                >
                  <Avatar
                    style={{ height: 30, width: 30 }}
                    alt={team_member.first_name + " " + team_member.last_name}
                    src={s3baseUrl + team_member.profile_pic}
                  />
                  <Typography variant="subtitle2" noWrap>
                    {team_member.first_name + " " + team_member.last_name}
                  </Typography>
                </Stack>
              ))}
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Status:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Label
                variant="outlined"
                color={data.is_active ? "success" : "error"}
              >
                {sentenceCase((data.is_active && "Active") || "Inactive")}
              </Label>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Created At:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2">
                {moment(data.createdAt).format("LLL")}
              </Typography>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-4">
              <Typography variant="subtitle2" noWrap>
                <b>Updated At:</b>
              </Typography>
            </div>
            <div className="col-8">
              <Typography variant="subtitle2">
                {moment(data.updatedAt).format("LLL")}
              </Typography>
            </div>
            {/*  */}
          </div>
        </Scrollbar>
      </Drawer>
      {/* </Form> */}
      {/* </FormikProvider> */}
    </>
  );
}
