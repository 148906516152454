import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  Avatar,
  CircularProgress,
  Card,
  Chip,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import { sentenceCase } from "change-case";
import { CircularLoader, Label } from "src/components";
import { get_unit_type_label, is_available } from "src/utils/utils_functions";
import { useEffect, useState } from "react";
import { _get_property_details } from "src/DAL/properties";
import { useSnackbar } from "notistack";
// import { _update_customer_password } from "src/DAL/customer/customer";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Details({
  isOpenDrawer,
  onOpenDrawer,
  onCloseDrawer,
  selectedRow,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(selectedRow);
  const [isLoading, setIsLoading] = useState(true);

  console.log(selectedRow, "--");
  const handleSetData = async (id) => {
    setIsLoading(true);
    const resp = await _get_property_details(id);
    if (resp.code === 200) {
      setData(resp.property);
      setIsLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (selectedRow.id) {
      handleSetData(selectedRow.id);
    }
  }, [isOpenDrawer]);

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: { width: 350, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            <b>Property's Details</b>
          </Typography>
          <IconButton onClick={onCloseDrawer}>
            <Icon icon="eva:close-fill" width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        {isLoading && (
          <CircularProgress
            className="m-auto"
            style={{ height: 30, width: 30 }}
          />
        )}

        {!isLoading && (
          <Scrollbar>
            <div className="row p-3 w-100">
              <div className="col-12 text-center mb-2">
                {data.image ? (
                  <img src={s3baseUrl + data.image} className="w-100 pb-4" />
                ) : (
                  <Typography variant="caption" noWrap>
                    Property Image Not Available
                  </Typography>
                )}
              </div>

              <div className="col-12 text-center mb-2">
                {data.image_2 && (
                  <img src={s3baseUrl + data.image_2} className="w-100 pb-4" />
                )}
              </div>
              {/*  */}
              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2">
                  <b>Property Name:</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2" noWrap>
                  {data.property_name}
                </Typography>
              </div>
              {/*  */}
              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2" noWrap>
                  <b>House Number:</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2" noWrap>
                  {data.house_number}
                </Typography>
              </div>
              {/*  */}
              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2">
                  <b>Address 1:</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2">{data.address}</Typography>
              </div>
              {/*  */}
              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2">
                  <b>Address 2:</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2">
                  {is_available(data.address_2)}
                </Typography>
              </div>
              {/*  */}

              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2" noWrap>
                  <b>Country:</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2" noWrap>
                  {is_available(data.country)}
                </Typography>
              </div>
              {/*  */}

              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2" noWrap>
                  <b>State:</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2" noWrap>
                  {is_available(data.state)}
                </Typography>
              </div>
              {/*  */}
              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2" noWrap>
                  <b>Bedrooms:</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2" noWrap>
                  {is_available(data.number_of_bedrooms)}
                </Typography>
              </div>
              {/*  */}
              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2" noWrap>
                  <b>Bathrooms:</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2" noWrap>
                  {is_available(data.number_of_bathrooms)}
                </Typography>
              </div>
              {/*  */}
              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2" noWrap>
                  <b>Electricity Provider:</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2">
                  {is_available(data.electricity_provider)}
                </Typography>
              </div>
              {/*  */}
              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2" noWrap>
                  <b>Digital TV Provider:</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2" noWrap>
                  {is_available(data.digital_tv_provider)}
                </Typography>
              </div>
              {/*  */}
              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2">
                  <b>Google Map Link:</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2">
                  {is_available(data.google_map_link)}
                </Typography>
              </div>
              {/*  */}
              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2" noWrap>
                  <b>Unit Type :</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2" noWrap>
                  {get_unit_type_label(data.unit_type)}
                </Typography>
              </div>
              {/*  */}

              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2" noWrap>
                  <b>Ownership Type:</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2" noWrap>
                  {get_unit_type_label(data.ownership_type)}
                </Typography>
              </div>
              {/*  */}

              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2" noWrap>
                  <b> Client (Owner)</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2" noWrap>
                  {data.client_id
                    ? data.client_first_name + " " + data.client_last_name
                    : "-"}
                </Typography>
              </div>

              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2" noWrap>
                  <b> ARO Manager</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2" noWrap>
                  {data.manager_id
                    ? data.manager_first_name + " " + data.manager_last_name
                    : "-"}
                </Typography>
              </div>
              {/*  */}

              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2" noWrap>
                  <b>Employee:</b>
                </Typography>
              </div>
              <div className="col-7">
                {data.employees.map((employee) => (
                  <Chip
                    size="small"
                    className="m-1 text-capitalize"
                    label={employee.first_name + " " + employee.last_name}
                  />
                ))}
              </div>
              {/*  */}

              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2" noWrap>
                  <b>Status:</b>
                </Typography>
              </div>
              <div className="col-7">
                <Label
                  variant="outlined"
                  color={data.is_active === 0 ? "error" : "success"}
                >
                  {sentenceCase((data.is_active === 0 && "banned") || "active")}
                </Label>
              </div>
              {/*  */}

              {/*  */}
              <Divider className="mb-2 mt-2" />
              <div className="col-12">
                <Typography variant="subtitle2">
                  <b>Property Notes:</b>
                </Typography>
              </div>
              <div className="col-12">
                <Typography variant="subtitle2">
                  {data.property_notes ? data.property_notes : "-"}
                </Typography>
              </div>

              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2" noWrap>
                  <b>Created At:</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2">
                  {moment(data.created_at).format("LLL")}
                </Typography>
              </div>
              {/*  */}

              <Divider className="mb-2 mt-2" />
              <div className="col-5">
                <Typography variant="subtitle2" noWrap>
                  <b>Updated At:</b>
                </Typography>
              </div>
              <div className="col-7">
                <Typography variant="subtitle2">
                  {moment(data.updated_at).format("LLL")}
                </Typography>
              </div>
              {/*  */}
            </div>
          </Scrollbar>
        )}
      </Drawer>
      {/* </Form> */}
      {/* </FormikProvider> */}
    </>
  );
}
