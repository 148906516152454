import { invokeApi } from "src/utils";

export const _dashboard_data = async () => {
  const requestObj = {
    path: `/v1/dashboard/dashboard_data`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
