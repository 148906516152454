import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  AvatarGroup,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
  CustomConfirmation,
} from "src/components";
import ListHead from "./components/ListHead";
import ListToolbar from "./components/ListToolbar";
import MoreMenu from "./components/MoreMenu";
import { TITLE_1, TITLE_2, ADD_ROUTE, EDIT_ROUTE, MAIN_ROUTE } from "./data";

import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSnackbar } from "notistack";
import { baseUri, s3baseUrl } from "src/config/config";
import Details from "./Details/Details";
import {
  get_currency_sign,
  get_priority_status,
  get_project_fee_status,
  get_project_status,
  is_available,
} from "src/utils/utils_functions";
import {
  _delete_project_by_admin,
  _get_projects_listing,
} from "src/DAL/projects";
import { Icon } from "@iconify/react";
import { _get_employee_listing } from "src/DAL/employee";
import { _get_manager_listing } from "src/DAL/manager";
import { _get_customer_listing } from "src/DAL/customers";
// import { _get_event_listing } from "src/DAL/events";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.project_title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Listing() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingEmployee, setIsLoadingEmployee] = useState(true);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [data, setData] = useState([]);

  const [clients, setClients] = useState([]);
  const [managers, setManagers] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [selectedRow, setSelectedRow] = useState([]);

  const [openDelete, setOpenDelete] = useState(false);
  // details drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDelete = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setIsDrawerOpen(true);
  };

  const handleCloseDetails = (row) => {
    setIsDrawerOpen(false);
  };

  const handleAgreeDelete = async () => {
    const resp = await _delete_project_by_admin(selectedRow.id);
    if (resp.code === 200) {
      setOpenDelete(false);
      handleSetData();
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredUsers = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const handleNav = () => {
    navigate(ADD_ROUTE);
  };

  const get_team = (team) => {
    if (team && !isLoading && !isLoadingEmployee) {
      const parse_team = JSON.parse(team);
      const _team = parse_team.map((team) =>
        employees.find((employee) => employee.id === team)
      );

      return _team;
    }
    return [];
  };

  const get_employees_list = async () => {
    const resp = await _get_employee_listing();
    if (resp.code === 200) {
      setEmployees(resp.employees);
      setIsLoadingEmployee(false);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const handleSetData = async () => {
    const resp = await _get_projects_listing();
    console.log(resp, "-user-listing");
    if (resp.code === 200) {
      setData(resp.projects);
      setIsLoading(false);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  useEffect(() => {
    handleSetData();
    get_employees_list();
  }, []);

  const TABLE_HEAD = [
    { id: "project_title", label: "Title", alignRight: false },
    { id: "project_status", label: "Project Status", alignRight: false },
    { id: "priority", label: "Priority Level", alignRight: false },
    { id: "client_id", label: "Project Client", alignRight: false },
    { id: "manager_id", label: "Project Manager", alignRight: false },
    { id: "team", label: "Team", alignRight: false },
    { id: "budget", label: "Client Budget", alignRight: false },
    { id: "expected_fee", label: "Expected Fee", alignRight: false },
    { id: "fee_paid", label: "Fee Status", alignRight: false },
    { id: "deadline", label: "Deadline Date", alignRight: false },
    { id: "end_date", label: "Start Date", alignRight: false },
    { id: "end_date", label: "End Date", alignRight: false },
    { id: "status", label: "Status", alignRight: false },
    { id: "createdAt", label: "Created At", alignRight: false },
    { id: "updatedAt", label: "Updated At", alignRight: false },
    { id: "" },
  ];

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title={TITLE_2}>
      <Container maxWidth="xl" className="mt-4">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          <Typography variant="h4">{TITLE_2}</Typography>
          <Button
            onClick={handleNav}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add New {TITLE_1}
          </Button>
        </Stack>

        <Card
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            mb: 1,
          }}
          className="theme-color-card"
        >
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            TITLE_1={TITLE_1}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, overflowX: "unset" }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      return (
                        <TableRow
                          style={{
                            borderLeft: `solid 4px ${
                              get_project_status(row.project_status)?.color
                            }`,
                            borderRadius: "15px 15px",
                          }}
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {row.project_image && (
                                <img
                                  className="custom-link"
                                  onClick={() => handleRowClick(row)}
                                  height={50}
                                  width={80}
                                  style={{
                                    objectFit: "cover",
                                    borderRadius: 5,
                                  }}
                                  // alt={firstName + " " + lastName}
                                  src={s3baseUrl + row.project_image}
                                />
                              )}
                              <Typography
                                onClick={() => handleRowClick(row)}
                                variant="subtitle2"
                                className="custom-link"
                                noWrap
                              >
                                {is_available(row.project_title)}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                              <Icon
                                fontSize={12}
                                className="me-2"
                                color={
                                  get_project_status(row.project_status)?.color
                                }
                                icon="pajamas:status-active"
                              />
                              {get_project_status(row.project_status)?.label}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                              <Icon
                                fontSize={16}
                                className="me-2"
                                color={get_priority_status(row.priority)?.color}
                                icon="material-symbols:flag"
                              />
                              {get_priority_status(row.priority).label}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            {row.client_id ? (
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  style={{ height: 30, width: 30 }}
                                  alt={
                                    row.client_first_name +
                                    " " +
                                    row.client_last_name
                                  }
                                  src={s3baseUrl + row.client_profile_pic}
                                />
                                <Typography
                                  variant="subtitle2"
                                  className="custom-link"
                                  noWrap
                                >
                                  {row.client_first_name +
                                    " " +
                                    row.client_last_name}
                                </Typography>
                              </Stack>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {row.manager_id ? (
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  style={{ height: 30, width: 30 }}
                                  alt={
                                    row.manager_first_name +
                                    " " +
                                    row.manager_last_name
                                  }
                                  src={s3baseUrl + row.manager_profile_pic}
                                />
                                <Typography
                                  variant="subtitle2"
                                  className="custom-link"
                                  noWrap
                                >
                                  {row.manager_first_name +
                                    " " +
                                    row.manager_last_name}
                                </Typography>
                              </Stack>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <AvatarGroup max={4}>
                              {get_team(row.team).map((team_member) => (
                                <Avatar
                                  style={{ height: 30, width: 30 }}
                                  alt={
                                    team_member.first_name +
                                    " " +
                                    team_member.last_name
                                  }
                                  src={s3baseUrl + team_member.profile_pic}
                                />
                              ))}
                            </AvatarGroup>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className="custom-link"
                              noWrap
                            >
                              {row.budget
                                ? is_available(
                                    get_currency_sign(row.currency) +
                                      " " +
                                      row.budget
                                  )
                                : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className="custom-link"
                              noWrap
                            >
                              {row.expected_fee
                                ? is_available(
                                    get_currency_sign(row.currency) +
                                      " " +
                                      row.expected_fee
                                  )
                                : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              color={
                                get_project_fee_status(row.fee_paid)?.variant
                              }
                            >
                              {sentenceCase(
                                get_project_fee_status(row.fee_paid)?.label
                              )}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            {row.deadline_date ? (
                              <>
                                {moment(row.deadline_date).format("LL")}

                                <div
                                  style={{
                                    fontWeight: 500,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  ({moment(row.deadline_date).toNow()})
                                </div>
                              </>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {row.start_date
                              ? moment(row.start_date).format("LL")
                              : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row.end_date
                              ? moment(row.end_date).format("LL")
                              : "-"}
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant="outlined"
                              color={row.is_active ? "success" : "error"}
                            >
                              {sentenceCase(
                                (row.is_active && "Active") || "Inactive"
                              )}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            {moment(row.created_at).format("LLL")}
                          </TableCell>{" "}
                          <TableCell align="left">
                            {moment(row.updated_at).format("LLL")}
                          </TableCell>
                          <TableCell align="right">
                            <MoreMenu
                              row={row}
                              handleDelete={handleDelete}
                              EDIT_ROUTE={EDIT_ROUTE}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={`Are you sure you want to delete this ${TITLE_1}?`}
        handleAgree={handleAgreeDelete}
      />

      <Details
        isOpenDrawer={isDrawerOpen}
        onCloseDrawer={handleCloseDetails}
        selectedRow={selectedRow}
        get_team={get_team}
      />
    </Page>
  );
}
