import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Iconify } from "src/components";
import { _get_customer_listing } from "src/DAL/customers";
import { _add_invoice } from "src/DAL/invoice";
import { _add_manager_by_admin } from "src/DAL/manager";
import { _upload_file } from "src/DAL/upload_file";
import { CURRENCIES_LIST, LIST_OF_MONTHS } from "src/utils/constant";
import { get_local_preview } from "src/utils/utils_functions";

const TITLE = "Invoice";
function Add() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState("");
  const [customersList, setCustomersList] = useState([]);
  const [inputs, setInputs] = useState({
    invoice_month: new Date().getMonth(),
    invoice_year: new Date().getFullYear(),
    due_date: "",
    currency: "NGN",
    amount: "",
    customer_id: "",
    invoice_file: "",
    is_active: "1",
  });

  const handleChange = async (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let invoice_file = inputs.invoice_file;
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const res = await _upload_file(formData);
      if (res.code === 200) {
        invoice_file = res.file_path;
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
        setIsLoading(false);
      }
    }
    const postDate = {
      due_date: inputs.due_date,
      invoice_month: `${inputs.invoice_month}`,
      invoice_year: `${inputs.invoice_year}`,
      currency: `${inputs.currency}`,
      amount: `${inputs.amount}`,
      customer_id: `${inputs.customer_id}`,
      is_active: `${inputs.is_active}`,
      invoice_file: invoice_file,
    };

    const res = await _add_invoice(postDate);
    if (res.code === 200) {
      enqueueSnackbar(res.message, { variant: "success" });
      handleBack();
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (e) => {
    setFile(e.target.files[0]);
  };

  const handleRemoveFile = (e) => {
    setFile("");
    setInputs({ ...inputs, invoice_file: "" });
  };

  const handleBack = async (e) => {
    navigate("/invoices");
  };

  const get_customers_list = async () => {
    const resp = await _get_customer_listing();
    if (resp.code === 200) {
      setCustomersList(resp.customers);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_customers_list();
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-6">
            <form onSubmit={handleSubmit} className="row  mt-4">
              <div className="col-12">
                <IconButton edge="end" onClick={handleBack}>
                  <Iconify icon={"eva:arrow-back-outline"} />
                </IconButton>
              </div>
              <div className="col-12 text-center mb-4">
                <h4>Add New {TITLE}</h4>
              </div>
              <div className="col-12 col-lg-12 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Invoice Month *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Invoice Month *"
                    required
                    name="invoice_month"
                    value={inputs.invoice_month}
                    onChange={handleChange}
                  >
                    {/* <MenuItem value={""}></MenuItem> */}
                    {LIST_OF_MONTHS.map((month) => (
                      <MenuItem value={month.value}>{month.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>{" "}
              <div className="col-12 col-lg-12 mb-3">
                <TextField
                  fullWidth
                  label="Year"
                  name="invoice_year"
                  type="number"
                  value={inputs.invoice_year}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* <div className="col-12 col-lg-12 mb-3">
                <TextField
                  fullWidth
                  type="date"
                  label="Due Date"
                  name="due_date"
                  value={inputs.due_date}
                  onChange={handleChange}
                  required
                />
              </div> */}
              <div className="col-12 col-lg-12 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Currency *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Currency *"
                    required
                    name="currency"
                    value={inputs.currency}
                    onChange={handleChange}
                  >
                    {CURRENCIES_LIST.map((option) => (
                      <MenuItem value={option.value}>
                        {option.sign + " " + option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-12 mb-3">
                <TextField
                  fullWidth
                  type="number"
                  label="Invoice Amount"
                  name="amount"
                  value={inputs.amount}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12 col-lg-12 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Customer *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Choose Customer *"
                    required
                    name="customer_id"
                    value={inputs.customer_id}
                    onChange={handleChange}
                  >
                    {customersList.map((customer) => (
                      <MenuItem value={customer.id}>
                        {customer.first_name + " " + customer.last_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-12 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Status *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status *"
                    required
                    name="is_active"
                    value={inputs.is_active}
                    onChange={handleChange}
                  >
                    <MenuItem value={"1"}>Active</MenuItem>
                    <MenuItem value={"0"}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {file && (
                <div className="col-12 col-lg-12 mb-3">
                  <b>Invoice Attachment:</b> <div>{file.name}</div>
                  <Button
                    fullWidth
                    className="mt-3"
                    component="span"
                    variant="outlined"
                    color="error"
                    onClick={handleRemoveFile}
                  >
                    Remove File
                  </Button>
                </div>
              )}
              {!file && (
                <div className="col-12 col-lg-12 mb-3">
                  <input
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                    text/plain, application/pdf, image/*"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={fileChangedHandler}
                  />
                  <label
                    htmlFor="contained-button-file"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Button
                      fullWidth
                      component="span"
                      variant="outlined"
                      color="primary"
                    >
                      Invoice Attachment
                    </Button>
                  </label>
                </div>
              )}
              <div className="col-12 mb-3 text-center">
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                >
                  Add {TITLE}
                </LoadingButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add;
