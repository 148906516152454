import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  Avatar,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import { sentenceCase } from "change-case";
import { Label } from "src/components";
import {
  get_department_label,
  get_employment_type,
  get_identification,
  get_material_status,
} from "src/utils/utils_functions";
// import { _update_customer_password } from "src/DAL/customer/customer";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Details({
  isOpenDrawer,
  onOpenDrawer,
  onCloseDrawer,
  selectedRow,
}) {
  const {
    first_name,
    last_name,
    email,
    phone,
    material_status,
    children,
    position,
    // password,
    date_of_birth,
    employment_type,
    state_of_origin,
    work_type,
    last_place_of_work,
    address,
    start_date,
    identification,
    department,
    notes,
    next_of_kin,
    kin_phone_number,
    kin_address,
    profile_pic,
    is_active,
    created_at,
    updated_at,
  } = selectedRow;

  console.log(selectedRow, "--selectedRow");
  return (
    <>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: { width: 350, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            <b>Employee's Details</b>
          </Typography>
          <IconButton onClick={onCloseDrawer}>
            <Icon icon="eva:close-fill" width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <div className="row p-3 w-100">
            <div className="col-12 text-center mb-2">
              {profile_pic ? (
                <img
                  alt={first_name + " " + last_name}
                  src={s3baseUrl + profile_pic}
                  className="w-50 pb-4"
                />
              ) : (
                <Typography variant="caption" className="col-7">
                  Profile Image Not Available
                </Typography>
              )}
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>First Name:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">{first_name}</Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Last Name:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">{last_name}</Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Email:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">{email}</Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Phone:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">{phone}</Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Start Date:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {start_date ? moment(start_date).format("LLL") : "-"}
              </Typography>
            </div>

            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Date of Birth:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {date_of_birth ? moment(date_of_birth).format("LLL") : "-"}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Address:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {address ? address : "-"}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Employment Type:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {employment_type ? get_employment_type(employment_type) : "-"}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Marital Status:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {material_status ? get_material_status(material_status) : "-"}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Children:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {children ? children : "-"}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Position:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {position ? position : "-"}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Identification:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {identification ? get_identification(identification) : "-"}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>State Of Origin:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {state_of_origin ? state_of_origin : "-"}
              </Typography>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Work Type:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {work_type ? work_type : "-"}
              </Typography>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Last Place Of Work:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {last_place_of_work ? last_place_of_work : "-"}
              </Typography>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Department:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {department ? get_department_label(department) : "-"}
              </Typography>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Next Of Kin:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {next_of_kin ? next_of_kin : "-"}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Kin Phone Number:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {kin_phone_number ? kin_phone_number : "-"}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Kin Address:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {kin_address ? kin_address : "-"}
              </Typography>
            </div>
            {/*  */}

            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Status:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Label
                variant="outlined"
                color={is_active === 0 ? "error" : "success"}
              >
                {sentenceCase((is_active === 0 && "banned") || "active")}
              </Label>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-12">
              <Typography variant="subtitle2">
                <b>Notes:</b>
              </Typography>
            </div>
            <div className="col-12">
              <Typography variant="subtitle2">{notes ? notes : "-"}</Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Created At:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {moment(created_at).format("LLL")}
              </Typography>
            </div>
            {/*  */}
            <Divider className="mb-2 mt-2" />
            <div className="col-5">
              <Typography variant="subtitle2">
                <b>Updated At:</b>
              </Typography>
            </div>
            <div className="col-7">
              <Typography variant="subtitle2">
                {moment(updated_at).format("LLL")}
              </Typography>
            </div>

            {/*  */}
          </div>
        </Scrollbar>
      </Drawer>
      {/* </Form> */}
      {/* </FormikProvider> */}
    </>
  );
}
