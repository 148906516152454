import { useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import { RowingRounded } from "@mui/icons-material";

// ----------------------------------------------------------------------

export default function UserMoreMenu({
  row,
  handleDelete,
  handleVerify,
  handleMoveToPending,
  handleMoveToPendingConfirmation,
}) {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleEdit = async (e) => {
    navigate("/invoices/edit-invoice/" + row.id, {
      state: {
        row,
      },
    });
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 270, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={handleEdit} sx={{ color: "text.secondary" }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <MenuItem
          onClick={() => handleDelete(row)}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        {row.invoice_status !== 0 && (
          <MenuItem
            onClick={() => handleMoveToPending(row)}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="entypo:back-in-time" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Move to pending"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {row.invoice_status !== 1 && (
          <MenuItem
            onClick={() => handleMoveToPendingConfirmation(row)}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify
                icon="ic:baseline-pending-actions"
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary="Move to pending confirmation"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        {row.invoice_status !== 2 && (
          <MenuItem
            onClick={() => handleVerify(row)}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify
                icon="line-md:circle-to-confirm-circle-transition"
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary="Move to confirmed"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
