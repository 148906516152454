import { invokeApi } from "src/utils";

export const _upload_file = async (data) => {
  const requestObj = {
    path: `/v1/files/upload_file`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
