import { invokeApi } from "src/utils";

export const _get_employee_listing = async () => {
  const requestObj = {
    path: `/v1/employee/get_employee_listing`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_employee = async (data) => {
  const requestObj = {
    path: `/v1/employee/add_employee`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_employee = async (id, data) => {
  const requestObj = {
    path: `/v1/employee/update_employee/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_employee = async (id) => {
  const requestObj = {
    path: `/v1/employee/delete_employee/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
