import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularLoader, Iconify } from "src/components";
import CountrySelect from "src/components/country_selctor/CountrySelect";
import { s3baseUrl } from "src/config/config";
import {
  _add_customer_by_admin,
  _get_customer_listing,
} from "src/DAL/customers";
import { _get_employee_listing } from "src/DAL/employee";
import { _get_manager_listing } from "src/DAL/manager";
import {
  _add_property_by_admin,
  _update_property_by_admin,
} from "src/DAL/properties";
import { _upload_file } from "src/DAL/upload_file";
import { OWNERSHIP_TYPE_LIST, UNIT_TYPE_LIST } from "src/utils/constant";
import { get_local_preview } from "src/utils/utils_functions";

function Edit() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [managers, setManagers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [inputs, setInputs] = useState({
    property_name: "",
    property_notes: "",
    house_number: "",
    address: "",
    address_2: "",
    country: "NG",
    state: "",
    number_of_bedrooms: "",
    number_of_bathrooms: "",
    electricity_provider: "",
    digital_tv_provider: "",
    google_map_link: "",
    unit_type: "",
    ownership_type: "",
    client_id: null,
    guest_id: null,
    manager_id: null,
    image: "",
    image_2: "",
    employees: [],
    is_active: "1",
  });

  const handleChange = async (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleChangeCountry = async (country) => {
    setInputs({ ...inputs, country: country.code });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let image_one = inputs.image;
    if (image) {
      const formData = new FormData();
      formData.append("file", image);
      const res = await _upload_file(formData);
      if (res.code === 200) {
        image_one = res.file_path;
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
        setIsLoading(false);
      }
    }

    let image_two = inputs.image;
    if (image2) {
      const formData = new FormData();
      formData.append("file", image2);
      const res = await _upload_file(formData);
      if (res.code === 200) {
        image_two = res.file_path;
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
        setIsLoading(false);
      }
    }

    let team = JSON.stringify(inputs.employees);

    console.log(team, "-team");

    const postDate = {
      ...inputs,
      image: image_one,
      image_2: image_two,
      employees: team,
    };
    const res = await _update_property_by_admin(state.data.id, postDate);
    if (res.code === 200) {
      handleBack();
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (e) => {
    setImage(e.target.files[0]);
  };

  const handleRemoveImage = (e) => {
    setImage("");
    setInputs({ ...inputs, image: "" });
  };

  const fileChangedHandler2 = (e) => {
    setImage2(e.target.files[0]);
  };

  const handleRemoveImage2 = (e) => {
    setImage2("");
    setInputs({ ...inputs, image_2: "" });
  };

  const handleBack = async (e) => {
    navigate("/properties");
  };

  const get_employee_profile = (id) => {
    const profile = employees.find((e) => e.id == id);

    return profile;
  };

  const get_customers_list = async () => {
    const resp = await _get_customer_listing();
    if (resp.code === 200) {
      setClients(resp.customers);
      setIsLoading(false);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const get_managers_list = async () => {
    const resp = await _get_manager_listing();
    if (resp.code === 200) {
      setManagers(resp.managers);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const get_employees_list = async () => {
    const resp = await _get_employee_listing();
    if (resp.code === 200) {
      setEmployees(resp.employees);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_customers_list();
    get_managers_list();
    get_employees_list();
  }, []);

  useEffect(() => {
    if (state) {
      setInputs({
        property_name: state.data.property_name,
        property_notes: state.data.property_notes,
        house_number: state.data.house_number,
        address: state.data.address,
        address_2: state.data.address_2,
        country: state.data.country,
        state: state.data.state,
        number_of_bedrooms: state.data.number_of_bedrooms,
        number_of_bathrooms: state.data.number_of_bathrooms,
        electricity_provider: state.data.electricity_provider,
        digital_tv_provider: state.data.digital_tv_provider,
        google_map_link: state.data.google_map_link,
        unit_type: state.data.unit_type,
        ownership_type: state.data.ownership_type,
        client_id: state.data.client_id
          ? String(state.data.client_id)
          : state.data.client_id,
        guest_id: state.data.guest_id,
        manager_id: state.data.manager_id,
        image: state.data.image,
        image_2: state.data.image_2,
        employees: JSON.parse(state.data.employees),
        is_active: state.data.is_active,
      });
    } else {
      navigate("/managers");
    }
  }, []);
  console.log(inputs, "--inputs");

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-10">
            <form onSubmit={handleSubmit} className="row  mt-4">
              <div className="col-12">
                <IconButton edge="end" onClick={handleBack}>
                  <Iconify icon={"eva:arrow-back-outline"} />
                </IconButton>
              </div>
              <div className="col-12 text-center mb-4">
                <h4>Edit Property</h4>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Property Name"
                  name="property_name"
                  value={inputs.property_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="House Number"
                  name="house_number"
                  type="number"
                  value={inputs.house_number}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Address 1"
                  name="address"
                  value={inputs.address}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Address 2"
                  name="address_2"
                  value={inputs.address_2}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <CountrySelect
                  label="Choose a country (Property)"
                  value={inputs.country}
                  onChange={handleChangeCountry}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="State (Property)"
                  name="state"
                  value={inputs.state}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Number of Bedrooms"
                  name="number_of_bedrooms"
                  type="number"
                  value={inputs.number_of_bedrooms}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Number of Bathrooms"
                  name="number_of_bathrooms"
                  type="number"
                  value={inputs.number_of_bathrooms}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Electricity Provider"
                  name="electricity_provider"
                  value={inputs.electricity_provider}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Digital TV Provider"
                  name="digital_tv_provider"
                  value={inputs.digital_tv_provider}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Google Map Link"
                  name="google_map_link"
                  value={inputs.google_map_link}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Unit Type *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Unit Type *"
                    required
                    name="unit_type"
                    value={inputs.unit_type}
                    onChange={handleChange}
                  >
                    {UNIT_TYPE_LIST.map((unit_type) => (
                      <MenuItem value={unit_type.value}>
                        {unit_type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Ownership Type *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Ownership Type *"
                    required
                    name="ownership_type"
                    value={inputs.ownership_type}
                    onChange={handleChange}
                  >
                    {OWNERSHIP_TYPE_LIST.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Client (Owner)*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Client (Owner)*"
                    required
                    name="client_id"
                    value={inputs.client_id}
                    onChange={handleChange}
                  >
                    {clients.map((option) => (
                      <MenuItem value={option.id}>
                        {option.first_name + " " + option.last_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    ARO Manager
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="ARO Manager"
                    name="manager_id"
                    value={inputs.manager_id}
                    onChange={handleChange}
                  >
                    {managers.map((option) => (
                      <MenuItem value={option.id}>
                        {option.first_name + " " + option.last_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Status *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status *"
                    required
                    name="is_active"
                    value={inputs.is_active}
                    onChange={handleChange}
                  >
                    <MenuItem value={"1"}>Active</MenuItem>
                    <MenuItem value={"0"}>Banned</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-lg-12 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Employee
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Employee"
                    name="employees"
                    multiple
                    value={inputs.employees}
                    onChange={handleChange}
                    MenuProps={{ style: { maxHeight: 500 } }}
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}
                      >
                        {selected.map((option) => (
                          <Chip
                            size="small"
                            style={{ fontSize: 10, padding: 0 }}
                            key={option}
                            label={
                              get_employee_profile(option)?.first_name +
                              " " +
                              get_employee_profile(option)?.last_name
                            }
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {employees.map((option) => (
                      <MenuItem value={option.id}>
                        <Checkbox
                          checked={
                            !!inputs.employees.find((id) => id == option.id)
                          }
                          // checked={checked}
                          // onChange={handleChange}
                        />
                        {option.first_name + " " + option.last_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-12 col-lg-12 mb-3">
                <TextField
                  fullWidth
                  label="Property Notes"
                  name="property_notes"
                  multiline
                  minRows={3}
                  value={inputs.property_notes}
                  onChange={handleChange}
                />
              </div>

              {image && (
                <div className="col-12 col-lg-6 mb-3">
                  <img width="50%" src={get_local_preview(image)} alt="" />
                  <Button
                    fullWidth
                    className="mt-3"
                    component="span"
                    variant="outlined"
                    color="error"
                    onClick={handleRemoveImage}
                  >
                    Remove Image
                  </Button>
                </div>
              )}

              {!image && inputs.image && (
                <div className="col-12 col-lg-6 mb-3">
                  <img width="100%" src={s3baseUrl + inputs.image} alt="" />
                  <Button
                    fullWidth
                    className="mt-3"
                    component="span"
                    variant="outlined"
                    color="error"
                    onClick={handleRemoveImage}
                  >
                    Remove Image
                  </Button>
                </div>
              )}
              {!image && !inputs.image && (
                <div className="col-12 col-lg-6 mb-3">
                  <input
                    accept="image/png,image/jpeg,image/jpg"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={fileChangedHandler}
                  />
                  <label
                    htmlFor="contained-button-file"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Button
                      fullWidth
                      component="span"
                      variant="outlined"
                      color="primary"
                    >
                      Upload Profile Image 1
                    </Button>
                  </label>
                </div>
              )}
              {/* 2nd */}
              {image2 && (
                <div className="col-12 col-lg-6 mb-3">
                  <img width="50%" src={get_local_preview(image2)} alt="" />
                  <Button
                    fullWidth
                    className="mt-3"
                    component="span"
                    variant="outlined"
                    color="error"
                    onClick={handleRemoveImage2}
                  >
                    Remove Image
                  </Button>
                </div>
              )}
              {!image2 && inputs.image_2 && (
                <div className="col-12 col-lg-6 mb-3">
                  <img width="100%" src={s3baseUrl + inputs.image_2} alt="" />
                  <Button
                    fullWidth
                    className="mt-3"
                    component="span"
                    variant="outlined"
                    color="error"
                    onClick={handleRemoveImage2}
                  >
                    Remove Image
                  </Button>
                </div>
              )}
              {!image2 && !inputs.image_2 && (
                <div className="col-12 col-lg-6 mb-3">
                  <input
                    accept="image/png,image/jpeg,image/jpg"
                    hidden
                    id="contained-button-file2"
                    multiple
                    type="file"
                    onChange={fileChangedHandler2}
                  />
                  <label
                    htmlFor="contained-button-file2"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Button
                      fullWidth
                      component="span"
                      variant="outlined"
                      color="primary"
                    >
                      Upload Profile Image 2 (Optional)
                    </Button>
                  </label>
                </div>
              )}
              <div className="col-12 mb-3 text-center">
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                >
                  Update Property
                </LoadingButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;
