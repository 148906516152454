// component
import { _get_user_from_local_storage } from "src/DAL/local_storage";
import { USER_TYPE } from "src/utils/constant";
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const profile = _get_user_from_local_storage();
const user_type = profile?.user_type;

const navConfigAdmin = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
    /* "children" key can be added in these objects, example:children:[{...}] */
  },

  {
    title: "Users",
    icon: getIcon("wpf:administrator"),
    path: "",
    children: [
      {
        title: "managers",
        path: "/managers",
        icon: getIcon("wpf:administrator"),
      },
      {
        title: "employees",
        path: "/employees",
        icon: getIcon("clarity:employee-group-line"),
      },
      {
        title: "guests",
        path: "/guests",
        icon: getIcon("fluent:guest-16-regular"),
      },

      {
        title: "clients",
        path: "/clients",
        icon: getIcon("ph:users-four"),
      },
    ],
  },

  {
    title: "Finance",
    path: "",
    icon: getIcon("fa-solid:file-invoice-dollar"),
    children: [
      {
        title: "Invoices",
        path: "/invoices",
        icon: getIcon("fa-solid:file-invoice-dollar"),
      },
    ],
  },

  {
    title: "Assets",
    path: "",
    icon: getIcon("bx:building-house"),
    children: [
      {
        title: "properties",
        path: "/properties",
        icon: getIcon("bx:building-house"),
      },
    ],
  },
  {
    title: "Projects",
    path: "",
    icon: getIcon("material-symbols:folder-copy"),
    children: [
      {
        title: "All Projects",
        path: "/projects",
        icon: getIcon("ant-design:project-outlined"),
      },
    ],
  },
];

const navConfigManager = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
    /* "children" key can be added in these objects, example:children:[{...}] */
  },

  {
    title: "employees",
    path: "/employees",
    icon: getIcon("clarity:employee-group-line"),
  },
  {
    title: "guests",
    path: "/guests",
    icon: getIcon("fluent:guest-16-regular"),
  },

  {
    title: "clients",
    path: "/clients",
    icon: getIcon("ph:users-four"),
  },
  {
    title: "Invoices",
    path: "/invoices",
    icon: getIcon("fa-solid:file-invoice-dollar"),
  },
];

const navConfigEmployee = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
    /* "children" key can be added in these objects, example:children:[{...}] */
  },
];

const navConfigCustomer = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  {
    title: "Invoices",
    path: "/invoices",
    icon: getIcon("fa-solid:file-invoice-dollar"),
  },
];

let navConfig = [];

if (user_type === USER_TYPE.admin) {
  navConfig = navConfigAdmin;
}

if (user_type === USER_TYPE.manager) {
  navConfig = navConfigManager;
}

if (user_type === USER_TYPE.employee) {
  navConfig = navConfigEmployee;
}

if (user_type === USER_TYPE.customer) {
  navConfig = navConfigCustomer;
}

export default navConfig;
