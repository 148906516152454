import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
  CustomConfirmation,
} from "src/components";
import UserListHead from "./components/UserListHead";
import UserListToolbar from "./components/UserListToolbar";
import UserMoreMenu from "./components/UserMoreMenu";
// mock
import USERLIST from "../../_mock/user";
//
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  _delete_manager_by_admin,
  _get_manager_listing,
} from "src/DAL/manager";
import { useSnackbar } from "notistack";
import { baseUri, s3baseUrl } from "src/config/config";
import {
  _delete_invoice,
  _get_all_invoices,
  _update_invoice_receipt,
  _update_invoice_status,
} from "src/DAL/invoice";
import { get_currency_sign, get_month_name } from "src/utils/utils_functions";
import Axios from "axios";
import { saveAs } from "file-saver";
import { USER_TYPE } from "src/utils/constant";
import { _get_user_from_local_storage } from "src/DAL/local_storage";
import { _upload_file } from "src/DAL/upload_file";
// ----------------------------------------------------------------------

const TABLE_HEAD_ADMIN = [
  { id: "name", label: "Customer", alignRight: false },
  { id: "invoice_month", label: "Invoice Month", alignRight: false },
  { id: "invoice_year", label: "Invoice Year", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "invoice_file", label: "Invoice Attachment", alignRight: false },
  { id: "created_at", label: "Created At", alignRight: false },
  { id: "updated_at", label: "Updated At", alignRight: false },
  { id: "invoice_receipt", label: "Invoice Receipt", alignRight: false },
  { id: "is_active", label: "Status", alignRight: false },
  { id: "" },
];

const TABLE_HEAD_CUSTOMER = [
  { id: "name", label: "Customer", alignRight: false },
  { id: "invoice_month", label: "Invoice Month", alignRight: false },
  { id: "invoice_year", label: "Invoice Year", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "invoice_file", label: "Invoice Attachment", alignRight: false },
  { id: "created_at", label: "Created At", alignRight: false },
  { id: "updated_at", label: "Updated At", alignRight: false },
  { id: "invoice_receipt", label: "Invoice Receipt", alignRight: false },
  { id: "" },
];

const profile = _get_user_from_local_storage();
const user_type = profile?.user_type;

let TABLE_HEAD = [];

if (user_type === USER_TYPE.admin || user_type === USER_TYPE.manager) {
  TABLE_HEAD = TABLE_HEAD_ADMIN;
}

if (user_type === USER_TYPE.customer) {
  TABLE_HEAD = TABLE_HEAD_CUSTOMER;
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        String(_user.invoice_year)
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1 ||
        String(_user.amount).toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        get_month_name(_user.invoice_month)
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1 ||
        _user.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const TITTLE = "Invoice";

export default function Listing() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [data, setData] = useState([]);

  const [selectedRow, setSelectedRow] = useState([]);

  const [selectedTab, setSelectedTab] = useState(0);

  const [openDelete, setOpenDelete] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [openPending, setOpenPending] = useState(false);
  const [openPendingConfirmation, setOpenPendingConfirmation] = useState(false);

  const handleDelete = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const handleVerify = (row) => {
    setSelectedRow(row);
    setOpenVerify(true);
  };

  const handleMoveToPending = (row) => {
    setSelectedRow(row);
    setOpenPending(true);
  };

  const handleMoveToPendingConfirmation = (row) => {
    setSelectedRow(row);
    setOpenPendingConfirmation(true);
  };

  const handleSelectedTab = (val) => {
    setSelectedTab(val);
  };

  const handleAgreeDelete = async () => {
    const resp = await _delete_invoice(selectedRow.id);
    if (resp.code === 200) {
      setOpenDelete(false);
      enqueueSnackbar(resp.message, { variant: "success" });
      handleSetData();
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const handleAgreeVerify = async () => {
    const postData = {
      invoice_id: `${selectedRow.id}`,
      invoice_status: "2",
    };
    const resp = await _update_invoice_status(postData);
    if (resp.code === 200) {
      enqueueSnackbar(`Invoice moved to Confirmed.`, {
        variant: "success",
      });
      setOpenVerify(false);
      handleSetData();
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const handleAgreeToPending = async () => {
    const postData = {
      invoice_id: `${selectedRow.id}`,
      invoice_status: "0",
    };
    const resp = await _update_invoice_status(postData);
    if (resp.code === 200) {
      enqueueSnackbar(`Invoice moved to Pending.`, {
        variant: "success",
      });
      setOpenPending(false);
      handleSetData();
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const handleAgreeToPendingConfirmation = async () => {
    const postData = {
      invoice_id: `${selectedRow.id}`,
      invoice_status: "1",
    };
    const resp = await _update_invoice_status(postData);
    if (resp.code === 200) {
      enqueueSnackbar(`Invoice moved to Pending Confirmation.`, {
        variant: "success",
      });
      setOpenPendingConfirmation(false);
      handleSetData();
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredUsers = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const handleNav = () => {
    navigate("/invoices/add-invoice");
  };

  const handleSetData = async () => {
    setIsLoading(true);
    const postData = { invoice_status: `${selectedTab}` };
    const resp = await _get_all_invoices(postData);
    if (resp.code === 200) {
      setData(resp.invoices);
      setIsLoading(false);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const handleUpdateReceipt = async (invoice_id, file) => {
    let invoice_receipt = "";
    const formData = new FormData();
    formData.append("file", file);
    const res = await _upload_file(formData);
    if (res.code === 200) {
      invoice_receipt = res.file_path;
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
      setIsLoading(false);
    }

    const postData = {
      invoice_id: `${invoice_id}`,
      invoice_receipt: invoice_receipt,
    };
    const resp = await _update_invoice_receipt(postData);
    if (resp.code === 200) {
      handleSetData();
      enqueueSnackbar(res.message, { variant: "success" });
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const handleFileClick = async (file, action) => {
    console.log(s3baseUrl + file);

    if (action === "view") {
      //Open the URL on new Window
      window.open(s3baseUrl + file);
      return;
    }
    Axios.get(`${s3baseUrl + file}`, { responseType: "blob" })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const _file = new Blob([response.data]);
        if (action === "download") {
          saveAs(_file, file);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleSetData();
  }, [selectedTab]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title={TITTLE + "s"}>
      <Container maxWidth="xl" className="mt-4">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          <Typography variant="h4">{TITTLE + "s"}</Typography>
          {(user_type === USER_TYPE.admin ||
            user_type === USER_TYPE.manager) && (
            <Button
              onClick={handleNav}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add New {TITTLE}
            </Button>
          )}
        </Stack>

        <div className="d-flex mb-4">
          <Button
            className="me-2"
            onClick={() => handleSelectedTab(0)}
            variant={selectedTab === 0 ? "contained" : "outlined"}
            color="warning"
          >
            Pending
          </Button>

          <Button
            className="me-2"
            onClick={() => handleSelectedTab(1)}
            variant={selectedTab === 1 ? "contained" : "outlined"}
            color="info"
          >
            Pending Confirmation
          </Button>

          <Button
            className="me-2"
            onClick={() => handleSelectedTab(2)}
            variant={selectedTab === 2 ? "contained" : "outlined"}
            color="success"
          >
            Confirmed
          </Button>
        </div>

        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
        >
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            title={TITTLE}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, overflowX: "unset" }}>
              {(user_type === USER_TYPE.admin ||
                user_type === USER_TYPE.manager) && (
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => {
                        const {
                          id,
                          due_date,
                          invoice_month,
                          invoice_year,
                          currency,
                          amount,
                          invoice_status,
                          customer_id,
                          receipt_file,
                          first_name,
                          last_name,
                          profile_pic,
                          email,
                          is_active,
                          added_by,
                          invoice_file,
                          created_at,
                          updated_at,
                        } = row;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  alt={first_name + " " + last_name}
                                  src={s3baseUrl + profile_pic}
                                />
                                <Typography variant="subtitle2" noWrap>
                                  {first_name + " " + last_name}
                                  <br />
                                  <Typography variant="body2" noWrap>
                                    {email}
                                  </Typography>
                                </Typography>

                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                ></Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              {get_month_name(invoice_month)}
                            </TableCell>
                            <TableCell align="left">{invoice_year}</TableCell>
                            <TableCell align="left">
                              <Typography
                                variant="subtitle2"
                                className="custom-link"
                                noWrap
                              >
                                {get_currency_sign(currency) + " "}
                                {amount}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              align="left"
                            >
                              {invoice_file ? (
                                <>
                                  <Button
                                    className="w-100"
                                    size="small"
                                    variant="outlined"
                                    color="inherit"
                                    onClick={() =>
                                      handleFileClick(invoice_file, "view")
                                    }
                                  >
                                    open
                                  </Button>{" "}
                                  <Button
                                    className="w-100 mt-1"
                                    size="small"
                                    variant="outlined"
                                    color={"inherit"}
                                    onClick={() =>
                                      handleFileClick(invoice_file, "download")
                                    }
                                  >
                                    Download
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    className="w-100"
                                    size="small"
                                    variant="outlined"
                                    color="info"
                                    disabled
                                  >
                                    Not Available
                                  </Button>{" "}
                                </>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {moment(created_at).format("LLL")}
                            </TableCell>{" "}
                            <TableCell align="left">
                              {moment(updated_at).format("LLL")}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              align="left"
                            >
                              {receipt_file ? (
                                <>
                                  <Button
                                    className="w-100"
                                    size="small"
                                    variant="outlined"
                                    color="inherit"
                                    onClick={() =>
                                      handleFileClick(receipt_file, "view")
                                    }
                                  >
                                    open
                                  </Button>{" "}
                                  <Button
                                    className="w-100 mt-1"
                                    size="small"
                                    variant="outlined"
                                    color={"inherit"}
                                    onClick={() =>
                                      handleFileClick(receipt_file, "download")
                                    }
                                  >
                                    Download
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    className="w-100"
                                    size="small"
                                    variant="outlined"
                                    color="info"
                                    disabled
                                  >
                                    Not Available
                                  </Button>
                                </>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              <Label
                                variant="outlined"
                                color={is_active === 0 ? "error" : "success"}
                              >
                                {sentenceCase(
                                  (is_active === 0 && "Inactive") || "active"
                                )}
                              </Label>
                            </TableCell>
                            <TableCell align="right">
                              <UserMoreMenu
                                row={row}
                                handleDelete={handleDelete}
                                handleVerify={handleVerify}
                                handleMoveToPending={handleMoveToPending}
                                handleMoveToPendingConfirmation={
                                  handleMoveToPendingConfirmation
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              )}

              {user_type === USER_TYPE.customer && (
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => {
                        const {
                          id,
                          due_date,
                          invoice_month,
                          invoice_year,
                          currency,
                          amount,
                          invoice_status,
                          customer_id,
                          first_name,
                          last_name,
                          profile_pic,
                          email,
                          is_active,
                          added_by,
                          invoice_file,
                          created_at,
                          updated_at,
                          receipt_file,
                        } = row;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  alt={first_name + " " + last_name}
                                  src={s3baseUrl + profile_pic}
                                />
                                <Typography variant="subtitle2" noWrap>
                                  {first_name + " " + last_name}
                                  <br />
                                  <Typography variant="body2" noWrap>
                                    {email}
                                  </Typography>
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              {get_month_name(invoice_month)}
                            </TableCell>
                            <TableCell align="left">{invoice_year}</TableCell>
                            <TableCell align="left">
                              <Typography
                                variant="subtitle2"
                                className="custom-link"
                                noWrap
                              >
                                {get_currency_sign(currency) + " "}
                                {amount}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              align="left"
                            >
                              {invoice_file ? (
                                <>
                                  <Button
                                    className="w-100"
                                    size="small"
                                    variant="outlined"
                                    color="inherit"
                                    onClick={() =>
                                      handleFileClick(invoice_file, "view")
                                    }
                                  >
                                    open
                                  </Button>{" "}
                                  <Button
                                    className="w-100 mt-1"
                                    size="small"
                                    variant="outlined"
                                    color={"inherit"}
                                    onClick={() =>
                                      handleFileClick(invoice_file, "download")
                                    }
                                  >
                                    Download
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    className="w-100"
                                    size="small"
                                    variant="outlined"
                                    color="info"
                                    disabled
                                  >
                                    Not Available
                                  </Button>{" "}
                                </>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {moment(created_at).format("LLL")}
                            </TableCell>{" "}
                            <TableCell align="left">
                              {moment(updated_at).format("LLL")}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              align="left"
                            >
                              {receipt_file ? (
                                <>
                                  <Button
                                    className="w-100"
                                    size="small"
                                    variant="outlined"
                                    color="inherit"
                                    onClick={() =>
                                      handleFileClick(receipt_file, "view")
                                    }
                                  >
                                    open
                                  </Button>{" "}
                                  <Button
                                    className="w-100 mt-1"
                                    size="small"
                                    variant="outlined"
                                    color={"inherit"}
                                    onClick={() =>
                                      handleFileClick(receipt_file, "download")
                                    }
                                  >
                                    Download
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <input
                                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                    text/plain, application/pdf, image/*"
                                    hidden
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={(e) =>
                                      handleUpdateReceipt(id, e.target.files[0])
                                    }
                                  />
                                  <label
                                    htmlFor="contained-button-file"
                                    className="d-flex align-items-center justify-content-center"
                                  >
                                    <Button
                                      fullWidth
                                      component="span"
                                      variant="outlined"
                                      color="primary"
                                    >
                                      Add Receipt
                                    </Button>
                                  </label>
                                </>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {invoice_status === 0 && (
                                <Button
                                  className="w-100"
                                  size="small"
                                  variant="contained"
                                  color="info"
                                  onClick={() =>
                                    handleMoveToPendingConfirmation(row)
                                  }
                                >
                                  Send for confirmation
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this " + TITTLE + "?"}
        handleAgree={handleAgreeDelete}
      />
      <CustomConfirmation
        open={openVerify}
        setOpen={setOpenVerify}
        title={"Are you sure you want to move this invoice to Confirmed?"}
        handleAgree={handleAgreeVerify}
      />

      <CustomConfirmation
        open={openPending}
        setOpen={setOpenPending}
        title={"Are you sure you want to move this invoice to Pending?"}
        handleAgree={handleAgreeToPending}
      />

      <CustomConfirmation
        open={openPendingConfirmation}
        setOpen={setOpenPendingConfirmation}
        title={
          "Are you sure you want to move this invoice to Pending Confirmation?"
        }
        handleAgree={handleAgreeToPendingConfirmation}
      />
    </Page>
  );
}
