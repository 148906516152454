import { invokeApi } from "src/utils";

export const _get_manager_listing = async () => {
  const requestObj = {
    path: `/v1/manager/get_manager_listing`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_manager_by_admin = async (data) => {
  const requestObj = {
    path: `/v1/manager/add_manager_by_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_manager_by_admin = async (id, data) => {
  const requestObj = {
    path: `/v1/manager/update_manager_by_admin/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_manager_by_admin = async (id) => {
  const requestObj = {
    path: `/v1/manager/delete_manager_by_admin/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
