import { invokeApi } from "src/utils";

export const _get_properties_listing = async () => {
  const requestObj = {
    path: `/v1/property/get_properties_listing`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_property_by_admin = async (data) => {
  const requestObj = {
    path: `/v1/property/add_property_by_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_property_by_admin = async (id, data) => {
  const requestObj = {
    path: `/v1/property/update_property_by_admin/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_property_by_admin = async (id) => {
  const requestObj = {
    path: `/v1/property/delete_property_by_admin/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_property_details = async (id) => {
  const requestObj = {
    path: `/v1/property/get_property_details/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
