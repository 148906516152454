export const USER_TYPE = {
  customer: 0,
  admin: 1,
  manager: 2,
  employee: 3,
};

export const EMPLOYMENT_TYPE = [
  { label: "Contractor", value: 1 },
  { label: "Full Time", value: 2 },
  { label: "Part Time", value: 3 },
  { label: "External", value: 4 },
];

export const CURRENCIES_LIST = [
  { label: "Dollars", value: "USD", sign: "$", icon: "ph:currency-dollar" },
  { label: "EURO", value: "EUR", sign: "€", icon: "material-symbols:euro" },
  { label: "GBP", value: "GBP", sign: "£", icon: "mdi:currency-gbp" },
  { label: "Naira", value: "NGN", sign: "₦", icon: "mdi:naira" },
];

export const DEPARTMENT_LIST = [
  { label: "Operations", value: 1 },
  { label: "Host", value: 2 },
  { label: "Senior Host", value: 3 },
  { label: "Cleaner", value: 4 },
  { label: "Other", value: 5 },
];

export const UNIT_TYPE_LIST = [
  { label: "Duplex", value: "duplex" },
  { label: "Flat", value: "flat" },
  { label: "House", value: "house" },
  { label: "Other", value: "other" },
];

export const OWNERSHIP_TYPE_LIST = [
  { label: "R2R", value: "r2r" },
  { label: "Landlord", value: "landlord" },
  { label: "ARO", value: "aro" },
  { label: "Other", value: "other" },
];

export const WORK_TYPE_LIST = [
  { label: "On-site", value: "on-site" },
  { label: "Remote", value: "remote" },
  { label: "Hybrid", value: "hybrid" },
];

export const IDENTIFICATION_TYPE_LIST = [
  { label: "Passport", value: "passport" },
  { label: "National ID", value: "national-id " },
];

export const LIST_OF_MONTHS = [
  {
    label: "January",
    value: 1,
  },
  {
    label: "February",
    value: 2,
  },
  {
    label: "March",
    value: 3,
  },
  {
    label: "April",
    value: 4,
  },
  {
    label: "May",
    value: 5,
  },
  {
    label: "June",
    value: 6,
  },
  {
    label: "July",
    value: 7,
  },
  {
    label: "August",
    value: 8,
  },
  {
    label: "September",
    value: 9,
  },
  {
    label: "October",
    value: 10,
  },
  {
    label: "November",
    value: 11,
  },
  {
    label: "December",
    value: 12,
  },
];

export const PROJECT_STATUS_LIST = [
  { label: "Not Started", value: 0, color: "rgb(150, 146, 146)" },
  { label: "In Progress", value: 1, color: "rgb(51 102 255)" },
  { label: "Completed", value: 2, color: "rgb(84, 214, 44)" },
  { label: "On Hold", value: 3, color: "rgb(255, 213, 0)" },
  { label: "Failed", value: 4, color: "rgb(255, 72, 66)" },
];

export const PRIORITY_STATUS_LIST = [
  { label: "Unknown", value: 0, color: "rgb(150, 146, 146)" },
  { label: "Low", value: 1, color: "rgb(255, 213, 0)" },
  { label: "Medium", value: 2, color: "rgb(84, 214, 44)" },
  { label: "High", value: 3, color: "rgb(255, 72, 66)" },
];

export const PROJECT_FEE_STATUS_LIST = [
  { label: "None", value: 0, color: "rgb(150, 146, 146)", variant: "default" },
  { label: "Unknown", value: 1, color: "rgb(255, 213, 0)", variant: "info" },
  { label: "Pending", value: 2, color: "rgb(84, 214, 44)", variant: "warning" },
  { label: "Paid", value: 3, color: "rgb(255, 72, 66)", variant: "success" },
  { label: "Late", value: 3, color: "rgb(255, 72, 66)", variant: "error" },
];

export const PROJECT_TYPE_LIST = [
  { label: "None", value: 0 },
  { label: "Renovation & listing", value: 2 },
  { label: "Renovation", value: 2 },
  { label: "Development", value: 3 },
  { label: "Cleaning", value: 4 },
  { label: "Cleaning & renovation", value: 5 },
  { label: "Consultancy/consultation", value: 6 },
  { label: "Call out", value: 7 },
  { label: "Other", value: 8 },
];

export const PROJECT_COMPLETION_LIST = [
  0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
  100,
];
