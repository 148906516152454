import { invokeApi } from "src/utils";

export const _get_projects_listing = async () => {
  const requestObj = {
    path: `/v1/project/get_projects_listing`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_project_by_admin = async (data) => {
  const requestObj = {
    path: `/v1/project/add_project_by_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_project_by_admin = async (id, data) => {
  const requestObj = {
    path: `/v1/project/update_project_by_admin/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_project_by_admin = async (id) => {
  const requestObj = {
    path: `/v1/project/delete_project_by_admin/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_project_details = async (id) => {
  const requestObj = {
    path: `/v1/project/get_project_details/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
